import { Htag } from "components";

import s from "./Wellbeing.module.css";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useEffect } from "react";
import { loadGuides } from "store/wellbeing/wellbeing-thunk";

import { ResourcesListComponent } from "components/SResources/ResourcesList.component";

import { getAllCategories } from "store/categories/categories.thunk";
import { PageType } from "store/system_resources/system_resource.interface";

export const WellBeingIndex = () => {
  const dispatch = useAppDispatch();

  const categories = useAppSelector((state) =>
    state.wellbeing.categories.map((ct) => {
      const updatedCt = {
        ...ct,
        guides: ct.guides?.map((gd) => {
          return { ...gd, type: "wguide" };
        }),
      };
      return updatedCt;
    })
  );

  useEffect(() => {
    dispatch(loadGuides());
    dispatch(getAllCategories());
  }, [dispatch]);

  return (
    <div className={s.wellbeingWrap}>
      <Htag tag="h1" className="mb32">
        Prioritise your wellbeing
      </Htag>
      {categories.length > 0 &&
        categories.map((ct, index) => (
          <div key={index} className="mb32">
            {" "}
            {ct.type == PageType.wellbeing && (
              <div>
                <Htag tag="h2">{ct.title}</Htag>
                {ct.guides && ct.guides.length > 0 && (
                  <ResourcesListComponent
                    show_in_row={4}
                    resources={ct.guides}
                    showTgas={false}
                  />
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
