import Echo from "laravel-echo";
import Pusher from "pusher-js";

declare global {
  interface Window {
    io: any;
    Echo: Echo;
  }
}

// window.io = io;
window.io = Pusher;
export function createSocketConnection(token: string) {
  const echoInstance = new Echo({
    broadcaster: "pusher",
    key: "devpost_key",
    cluster: "mt1",
    wsHost: process.env.REACT_APP_SOCKET_BASE_URL,
    wsPort: process.env.REACT_APP_SOCKET_HOST,
    forceTLS: true,
    authEndpoint: `${process.env.REACT_APP_API_ENDPOINT}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  echoInstance.connector.pusher.connection.bind("error", (err: any) => {
    console.error("Socket connection error:", err);
    // Handle the error (e.g., show a notification, log, or retry)
  });

  // Listen for failed authorization
  echoInstance.connector.pusher.connection.bind(
    "state_change",
    (states: any) => {
      if (states.current === "failed") {
        console.error("Socket connection failed to authorize.");
      }
    }
  );

  window.Echo = echoInstance;
}
