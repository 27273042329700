import { Button } from "components/Button/Button.component";
import s from "./DashboardStepsInfo.module.css";
import { useNavigate } from "react-router-dom";
import { Card } from "components/Card/Card.component";
import { Htag } from "components";

const textContext = [
  "you'll do lots of preparation to get ready for the legal process. These are small tasks that will help you feel secure. Well done for getting started.",
  "you'll apply for a divorce online if you haven't already. Dealing with the official process can feel intense. We've got lots of support to help you navigate it with confidence.",
  "you'll get an understanding of your financial landscape. Some people find this step overwhelming, others appreciate the clarity. We're here to help either way.",
  "you'll negotiate finances with your ex. Many people find this to be the hardest part of divorce. We've got templates to help you communicate during difficult moments.",
  "you'll do all the paperwork to officially get divorced and formalise your financial deal. There's a lot of admin to do, and it's important to get it right. We've organised and explained everything for you. You're nearly there!",
  " you'll carry out your financial agreement with your ex, and do a few final tasks like updating your will. We know how tiring this can be, but completing this final step will help you enter a new chapter with everything sorted."
];

export const DashboardStepsInfo = ({...props}): JSX.Element => {
  const navigate = useNavigate();
  
  return (
    <section className={s.divorce_pathway_wrap}>
      <div className={s.divorce_pathway_subtitle}>
        <Button
          apearance="default"
          onClick={() => navigate("/dashboard/pathway")}
        >
          See whole pathway
        </Button>
      </div>
      <Card
        className={s.userPathStatus}
        onClick={() => navigate(`/dashboard/milestone/${props.milestone?.id}`)}
      >
        <Htag tag="h2">
          You're on Step {props.milestone?.id}: {props.milestone?.title}
        </Htag>
        <div className={s.stepIntroText}>
          <img src="../assets/images/am.png" alt="" />
          <p>
            During Step {props.milestone?.id},{" "}
            {textContext[props.milestone?.id - 1]}
          </p>
        </div>
        <Button
          apearance="default"
          onClick={() =>
            navigate(`/dashboard/milestone/${props.milestone?.id}`)
          }
        >
          Go to Step {props.milestone?.id}
        </Button>
      </Card>
    </section>
  );
};
