import { forwardRef } from "react";
import s from "./StackItem.module.css";
import { StackItemProps } from "./StackItem.props";
import cn from "classnames";
import { Chip } from "@mui/material";
import { getTypeArticle } from "utils/utils";
import { ClockIcon } from "components";

export const StackItemComponent = forwardRef<HTMLDivElement, StackItemProps>(
  ({ data, className, ...props }: StackItemProps, ref) => {
    let type = undefined;
    if (typeof data.type == "string") {
      type = getTypeArticle(data.type);
    } else if (typeof data.type == "number") {
      type = "Template";
    }
    return (
      <div ref={ref} {...props} className={cn(s.stackItem, className)}>
        <p className="b1">{data.title.replace(/\.[^/.]+$/, "")}</p>
        {(type || data.reading_time) && (
          <div className={s.stack_meta}>
            {type && (
              <Chip
                label={type.toLowerCase()}
                variant="outlined"
                size="medium"
                className={s.mailChimp}
              />
            )}
            {data.reading_time !== "null" && data.reading_time && (
              <p className={s.guide_meta_time}>
                <ClockIcon /> {data.reading_time}
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
);
