import { Box, Modal } from "@mui/material";
import { TicketModalViewProps } from "./TicketModalView.props";
import s from "./TicketModalView.module.css";
import cn from "classnames";
import { CloseIcon } from "..";
const style = {
  boxShadow: 24,
  p: 4,
  transition: "all 0.2s ease",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  "*::-webkit-scrollbar": {
    width: ".01em",
  },
  "*::-webkit-scrollbar-track": {
    // "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0)",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0)",
    outline: "0px solid slategrey",
    borderRadius: "10px",
  },
};

export const TicketModalView = ({
  children,
  open,
  close,
}: TicketModalViewProps): JSX.Element => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={s.modalStyles}
      disableScrollLock={false}
    >
      <div className={cn(s.modalWrap)}>
        <div onClick={close} className={s.close}>
          Close <CloseIcon />
        </div>
        <Box sx={style} className={s.styledBox}>
          {children}
        </Box>
      </div>
    </Modal>
  );
};
