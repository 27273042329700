import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import { IExtraResource, IExtraResources } from "./extra_resource.interface";
import {
  addExtraResource,
  deleteEResource,
  get_all_extra_resources,
  get_single_eresource,
  updateExtraResource,
} from "./extra_resource-thunk";

const initialState: IExtraResources = {
  resources: [],
  error: "",
  loading: false,
};

const extraResourcesState = createSlice({
  name: "@@specialResources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(
        get_all_extra_resources.fulfilled,
        (state: IExtraResources, action: PayloadAction<IExtraResource[]>) => {
          state.resources = action.payload;
        }
      )
      .addCase(
        get_single_eresource.fulfilled,
        (state: IExtraResources, action: PayloadAction<IExtraResource>) => {
          state.resources = state.resources.filter(
            (resource) => resource.id !== action.payload.id
          );

          state.resources.push(action.payload);
        }
      )
      .addCase(deleteEResource.fulfilled, (state: IExtraResources, action) => {
        state.resources = state.resources.filter(
          (resource) => resource.id !== action.payload.id
        );
      })
      .addCase(
        updateExtraResource.fulfilled,
        (state: IExtraResources, action) => {
          state.resources = state.resources.map((x) => {
            if (x.id == action.payload.resource.id) {
              return action.payload.resource;
            }
            return x;
          });
        }
      )

      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export default extraResourcesState.reducer;
