import {
  Alert,
  FormControl,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Button,
  EditorComponent,
  ErrorField,
  Htag,
  InputFileComponent,
  InputImageComponent,
  TextArea,
} from "components";
import s from "../SpecialSources.module.css";
import { NavLink, useParams } from "react-router-dom";

import {
  get_single_sresource,
  updateSystemResource,
} from "store/system_resources/system_resource-thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFormik } from "formik";
import { validationCreateSchema } from "../validation";
import {
  FileProps,
  IAttach,
} from "store/system_resources/system_resource.interface";
import { milestones } from "store/milestones/milestones-interface";
import { Resource } from "models/Guide.model";

import cn from "classnames";
import InputTags from "components/Tags/Tags.component";
import { commonSelectProps } from "../WellbeingAdmin";
import { getAllCategories } from "store/categories/categories.thunk";

export const EditSRPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const system_resource = useAppSelector((state) =>
    state.specialResourcesState.resources_db.filter(
      (resource) => resource.id == Number(id)
    )
  )[0];

  const categories = useAppSelector((state) => state.allSpaces.categories);

  const [currentAttachments, setAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);
  const [attachmentsOld, setOldAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);

  const { error } = useAppSelector((state) => state.specialResourcesState);

  const formik = useFormik({
    initialValues: new Resource(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      if (!id) return;

      const updatedValue = formik.initialValues.formData(values);

      try {
        dispatch(
          updateSystemResource({
            sr_id: id,
            sr_data: updatedValue,
          })
        )
          .unwrap()
          .then(() => {
            setOpenSnackbar(true);
          });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  useEffect(() => {
    if (!id) return;
    if (!system_resource || !system_resource.content) {
      dispatch(get_single_sresource(id));
    }
  }, []);

  useEffect(() => {
    if (!system_resource) return;
    try {
      const fields = {
        content: system_resource.content || "",
        milestone_id: system_resource.milestone_id || null,
        title: system_resource.title || "",
        unique_number: system_resource.unique_number,
        reading_time: system_resource.reading_time || "",
        category_id: system_resource.category_id || null,
        image: system_resource.image,
        tags: system_resource.tags,
        domestic_abuse: system_resource.domestic_abuse,
      };

      Object.entries(fields).forEach(([key, value]) => {
        formik.setFieldValue(key, value);
      });
      setOldAttachments(system_resource.attachments);
    } catch (error) {
      console.error("Error fetching guide:", error);
    }
  }, [system_resource]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  return (
    <div className={s.formsWrap}>
      <div className={s.post_header}>
        <Htag tag="h3">Edit resource: "{formik.values.title}" </Htag>
        <Htag tag="h4">Unique number: {formik.values.unique_number} </Htag>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title</Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={cn(s.formField, s.reading_timeField)}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.reading_time}
              onChange={formik.handleChange}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload attachments
          </Htag>
          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="EditSR"
          />
        </div>

        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            name="content"
            handleChange={(e) => formik.setFieldValue("content", e)}
          />

          {formik.touched.content && formik.errors.content && (
            <ErrorField error={formik.errors.content} />
          )}
        </div>
        <div>
          <Htag tag="h3">Add tags:</Htag>

          <InputTags
            hashtag={formik.values.tags ? formik.values.tags : []}
            handleTags={(data) => formik.setFieldValue("tags", data)}
          />
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Domestic Abuse:</Htag>
       
          <TextArea
            error={
              formik.touched.domestic_abuse && formik.errors.domestic_abuse
                ? formik.errors.domestic_abuse
                : ""
            }
            name="domestic_abuse"
            id="domestic_abuse"
            value={formik.values.domestic_abuse || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Type your text here, max 500 characters"
          />
        </div>
        <div className={cn(s.selectsWrap, "mb32")}>
          {system_resource?.space_id == 1 && (
            <div className={s.select}>
              <Htag tag="h4" className="mb10">
                Connect to divorce step:
              </Htag>
              {
                <TextField
                  {...commonSelectProps}
                  name="milestone_id"
                  label={formik.values.milestone_id === null ? "Task is…" : ""}
                  defaultValue={
                    formik.values.milestone_id === null
                      ? ""
                      : formik.values.milestone_id
                  }
                  value={
                    formik.values.milestone_id === null
                      ? ""
                      : formik.values.milestone_id
                  }
                  onChange={formik.handleChange}
                >
                  {" "}
                  {milestones.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              }
              {error &&
                Array.isArray(error) &&
                error.map((item, index) => (
                  <ErrorField
                    key={index}
                    error={`Server error: ${Object.values(item)[0]}`}
                  />
                ))}
            </div>
          )}

          {system_resource?.space_id == 2 && (
            <div className={s.select}>
              <Htag tag="h4" className="mb10">
                Choose category:
              </Htag>
              {
                <TextField
                  {...commonSelectProps}
                  name="category_id"
                  label={
                    formik.values.category_id === null ? "Category is…" : ""
                  }
                  defaultValue={
                    !formik.values.category_id ? "" : formik.values.category_id
                  }
                  value={
                    formik.values.category_id === null
                      ? ""
                      : formik.values.category_id
                  }
                  onChange={formik.handleChange}
                >
                  <MenuItem value={JSON.stringify(null)}>
                    <em>None</em>
                  </MenuItem>
                  {categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              }
            </div>
          )}
        </div>

        {error && typeof error == "string" && <ErrorField error={error} />}
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/guides/edit-guide/${id}/preview`}
          >
            Preview
          </NavLink>
        </p>

        <Button apearance="secondary" type="submit">
          Save
        </Button>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          System resource "{system_resource?.title}" is updated!
        </Alert>
      </Snackbar>
    </div>
  );
};
