import { useFormik } from "formik";

import s from "../User.module.css";
import * as yup from "yup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";

import cn from "classnames";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { updateProfile } from "store/userInfo/user-thunk";
import { ProfileUpdate } from "store/userInfo/user-interface";

export const validationSignupSchema = yup.object({
  name: yup
    .string()
    .min(2, "Name should be at least 2 characters length")
    .required("Name is required"),
});

export const FinancialPicture = (): JSX.Element => {
  const profile = useAppSelector((state) => state.userState.user);
  const debts = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.debts || []
  );

  const dispatch = useAppDispatch();

  const [updateFields, setUpdateFields] = useState<Partial<ProfileUpdate>>({});

  const formik = useFormik({
    initialValues: profile,
    validationSchema: validationSignupSchema,
    onSubmit: () => {
      dispatch(updateProfile(updateFields));
    },
  });

  const [editable, setEditable] = useState(false);
  const editInfo = () => {
    setEditable(true);
  };

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;

    formik.handleChange(ev);
    setUpdateFields({
      ...updateFields,
      [`${name}`]: value,
    });
  };

  return (
    <>
      <form id="form-1" onSubmit={formik.handleSubmit} className={s.form}>
        {profile.questionnaires.generalQuestionnary && (
          <FormControl fullWidth className={s.formControl}>
            <label className={s.label}>Your family home</label>
            <div>
              Your family home is {""}
              {editable ? (
                <RadioGroup
                  row
                  name="questionnaires.generalQuestionnary.home_property"
                  className={s.radioboxes}
                >
                  <FormControlLabel
                    value="rented"
                    control={
                      <Radio color="default" onChange={updateInterview} />
                    }
                    label="rented"
                    checked={
                      formik.values.questionnaires.generalQuestionnary
                        ?.home_property === "rented"
                    }
                  />
                  <FormControlLabel
                    value="owned"
                    control={
                      <Radio color="default" onChange={updateInterview} />
                    }
                    label="owned"
                    checked={
                      formik.values.questionnaires.generalQuestionnary
                        ?.home_property === "owned"
                    }
                  />
                </RadioGroup>
              ) : (
                formik.values.questionnaires.generalQuestionnary?.home_property
              )}
            </div>
          </FormControl>
        )}
        {profile.questionnaires.generalQuestionnary && (
          <FormControl fullWidth className={s.formControl}>
            <label className={s.label}>
              Your financial picture may include
            </label>
            <FormGroup>
              <ul>
                {[...debts]
                  .sort((a, b) => Number(b.value) - Number(a.value))
                  .map((item, index) => (
                    <li key={index}>
                      {!editable ? (
                        <p
                          className={cn({
                            [s.labelFalse]: !item.value,
                          })}
                        >
                          {item.label}
                        </p>
                      ) : (
                        <FormControlLabel
                          key={index}
                          value={
                            formik.values.questionnaires.generalQuestionnary
                              ?.debts[index].value
                          }
                          control={<Checkbox color="default" />}
                          name={`questionnaires.generalQuestionnary.debts[${index}].value`}
                          label={item.label}
                          disabled={true}
                          checked={
                            formik.values.questionnaires.generalQuestionnary
                              ?.debts[index].value
                          }
                        />
                      )}
                    </li>
                  ))}
              </ul>
            </FormGroup>
          </FormControl>
        )}

        <FormControl className={s.formControl}>
          <label className={s.label}>
            The following might be relevant to your case
          </label>

          <p
            className={cn({
              [s.labelFalse]:
                formik.values.questionnaires.generalQuestionnary
                  ?.inherited_wealth == "no",
            })}
          >
            Inherited wealth
          </p>

          <p
            className={cn({
              [s.labelFalse]:
                formik.values.questionnaires.generalQuestionnary
                  ?.nuptial_agreement == "no",
            })}
          >
            A prenup or postnup
          </p>
        </FormControl>
        {/* {profile.questionnaires.generalQuestionnary && (
          <div className={s.buttonGroup}>
            {!editable ? (
              <span onClick={editInfo} className={s.editBtn}>
                Edit
              </span>
            ) : (
              <Button apearance="disable" type="submit" disabled={!editable}>
                Save
              </Button>
            )}
          </div>
        )} */}
      </form>
    </>
  );
};
