import { AnyAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { resetToDefault } from "./login/login-reducer";
import { IInitState } from "./root-interface";

export enum API_TYPE {
  GET = 0,
  POST,
  POST_DATA,
  PUT,
  DELETE,
}
interface IApiHeader {
  method: string;
  headers: {
    Accept?: string;
    Authorization: string;
    responseType?: string;
  };
}

export const getHandler = (getState: () => unknown) => {
  const { userState } = getState() as IInitState;
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
    },
  };
  return header;
};

export const putHandler = (getState: () => unknown, data: unknown) => {
  const { userState } = getState() as IInitState;
  const header = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: data,
  };
  return header;
};

export const postHandler = (getState: () => unknown, data: unknown) => {
  const { userState } = getState() as IInitState;
  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: data,
  };
  return header;
};

export const postFormHandler = (getState: () => unknown, data: unknown) => {
  const { userState } = getState() as IInitState;
  const header = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + userState.token,
    },
    body: data,
  };
  return header;
};

export const deleteHandler = (getState: () => unknown) => {
  const { userState } = getState() as IInitState;
  const header = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
    },
  };
  return header;
};

export const returnApiRequestAnswer = async (
  response: Response,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  rejectWithValue: (value: string) => any
) => {
  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      console.log("Unauthorased");
      dispatch(resetToDefault());
      return;
    }

    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result.errors) {
          return rejectWithValue(result.errors[0]);
        } else if (result.message) return rejectWithValue(result.message);
        rejectWithValue(result);
      });
  } else {
    return response.json();
  }
};

export const getRequestFabric = async (
  type: number,
  getState: () => unknown,
  url: string,
  rejectWithValue: (value: string) => any,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  data?: unknown
) => {
  let header: IApiHeader = {} as IApiHeader;

  switch (type) {
    case API_TYPE.GET:
      header = getHandler(getState);
      break;
    case API_TYPE.POST:
      header = postHandler(getState, data);
      break;
    case API_TYPE.POST_DATA:
      header = postFormHandler(getState, data);
      break;

    case API_TYPE.PUT:
      header = putHandler(getState, data);
      break;
    case API_TYPE.DELETE:
      header = deleteHandler(getState);
      break;
    default:
      header = getHandler(getState);
      break;
  }

  const response = await fetch(url, header);

  return await returnApiRequestAnswer(response, dispatch, rejectWithValue);
};
