import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_SYSTEM_RESOURCES,
  GET_RESENT_RESOURCES,
  GET_SINGLE_RESOURCE,
  GET_SPACE_RESOURCES,
  GET_SYSTEM_RESOURCES,
  UPDATE_SYSTEM_RESOURCE,
} from "../config";
import { IInitState } from "../root-interface";

import { API_TYPE, getRequestFabric } from "../thunk-helper";
import { Guide } from './system_resource.interface';

export const add_system_resources = createAsyncThunk<
  { success: string },
  string[],
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/adding",
  async function (unique_numbers, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      ADD_SYSTEM_RESOURCES(),
      rejectWithValue,
      dispatch,
      JSON.stringify({ unique_numbers: unique_numbers })
    );
  }
);

export const get_system_resources = createAsyncThunk<
  Guide[],
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/getting",
  async function (type, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SYSTEM_RESOURCES(type),
      rejectWithValue,
      dispatch
    );
  }
);
export const get_space_resources = createAsyncThunk<
  Guide[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/gettingSpace",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SPACE_RESOURCES(),
      rejectWithValue,
      dispatch
    );
  }
);
export const get_recent_resources = createAsyncThunk<
  Guide[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/gettingRecent",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_RESENT_RESOURCES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_single_sresource = createAsyncThunk<
  Guide,
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/gettingSingleResource",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_RESOURCE(Number(id)),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateSystemResource = createAsyncThunk<
  { success: string; resource: Guide },
  {
    sr_id: string;
    sr_data: FormData;
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@systemresources/updateResource",
  async function ({ sr_id, sr_data }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_SYSTEM_RESOURCE(sr_id),
      rejectWithValue,
      dispatch,
      sr_data
    );
  }
);
