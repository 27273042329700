import { Chip, List, ListItem } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Card, ClockIcon, NoImgIcon } from "components";

import s from "./Resource.module.css";
import cn from "classnames";

import { useEffect, useRef, useState } from "react";
import { getArticleLink } from "controlls/use-link";
import { getVariableForWidth } from "utils/utils";
import { Guide } from "store/system_resources/system_resource.interface";

interface IResourcesList {
  resources: Guide[];
  show_in_row: number;
  showTgas: boolean;
}

export const ResourcesListComponent = ({
  resources,
  show_in_row,
  showTgas,
}: IResourcesList) => {
  const navigate = useNavigate();
  const [tags, setTags] = useState<string[]>([]);
  const [resourceList, setResourceList] = useState<Guide[]>([]);
  const ref = useRef<HTMLInputElement>(null);

  const getRightId = (item: Guide, type?: string) => {
    const id = item.resource_id ? item.resource_id : item.id;

    if (item.is_wellbeing_resource)
      return getArticleLink("wellbeing_resource", Number(id));
    if (type == "wresources")
      return getArticleLink("wellbeing_resource", Number(id));
    if (type) {
      return getArticleLink(type, Number(id));
    } else {
      return `/dashboard/guides/${item.id}`;
    }
  };

  const btnText = ["See all guides", "Hide guides"];
  const [activeTag, setActiveTag] = useState("");
  const [btnState, setBtnState] = useState(0);

  const [showIndex, setShowIndex] = useState(show_in_row);

  useEffect(() => {

    setResourceList(resources);
    setShowIndex(show_in_row);
  }, [resources]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [ref.current?.clientWidth, resources]);

  const handleResize = () => {
    setShowIndex(
      getVariableForWidth(window.innerWidth) !== "small"
        ? show_in_row
        : resources.length
    );
  };

  useEffect(() => {
    const tempArr = [...resources].slice(0, showIndex);

    setResourceList(tempArr);
  }, [showIndex, resources]);



  useEffect(() => {
    if (!showTgas) return;

    const tags = [...resources]
      .reduce(
        (accumulator: any[], currentValue) =>
          currentValue.tags
            ? [...accumulator, ...currentValue.tags]
            : accumulator,
        []
      )
      .filter(function (value, index, array) {
        return array.indexOf(value) === index;
      });
    if (tags) setTags(tags);
  }, [resources]);

  const filterSourcesByTag = (tag: string) => {
    if (!showTgas) return;
    setActiveTag(tag);

    if (tag == "") {
      setResourceList(resources);
      return;
    }
    const filteredResources = [...resources].filter(
      (item: any) => item.tags && item.tags.find((el: any) => el == tag)
    );

    setResourceList(filteredResources);
  };

  const buttonStyle = () => {
    if (btnState == 0) {
      setBtnState(1);

      setShowIndex(resources.length);
    } else {
      setBtnState(0);
      setShowIndex(show_in_row);
    }
  };

  return (
    <>
      <div className={s.filterWrap}>
        <div className={cn(s.tagsList, "mb15", s.mobile_scroll_container)}>
          {showTgas && (
            <Chip
              className={s.tag}
              label={"All"}
              variant="outlined"
              onClick={() => filterSourcesByTag("")}
            />
          )}
          {showTgas &&
            tags.map((tag, index) => (
              <Chip
                className={cn(s.tag, {
                  [s.activeTag]: tag == activeTag,
                })}
                key={index}
                label={tag}
                variant="outlined"
                onClick={() => filterSourcesByTag(tag)}
              />
            ))}
        </div>
        <span
          className={cn(
            {
              [s.showBtn]: resources.length > showIndex || btnState == 1,
              [s.hideBtn]: resources.length <= show_in_row,
            },
            "hover-underline-animation_back"
          )}
          onClick={buttonStyle}
        >
          {btnText[btnState]}
        </span>
      </div>

      <div className={s.mobile_scroll_container} ref={ref}>
        <List
          className={s.resourceList}
          style={{
            gridTemplateColumns: `repeat( ${show_in_row}, 1fr)`,
          }}
        >
          {resourceList.map((resource: Guide) => (
            <ListItem
              key={`${resource.id}${Math.random()}`}
              className={s.listItem}
            >
              <Card
                className={s.resource_card}
                onClick={() =>
                  navigate(`${getRightId(resource, resource.type)}`)
                }
              >
                <div className={cn(s.resource_preview)}>
                  {resource.image && typeof resource.image == "string" ? (
                    <img src={resource.image} alt="" />
                  ) : (
                    <NoImgIcon />
                  )}
                </div>
                <div className={s.resource_meta}>
                  <p className='b2'> {resource.title}</p>
                  {/* <p> {resource.unique_number}</p> */}
                  <div className={s.resource_meta_extra}>
                    <Chip
                      label="Guide"
                      variant="outlined"
                      size="medium"
                      className={s.chip}
                    />
                    {resource.reading_time && (
                      <span className={s.resource_meta_readingTime}>
                        <ClockIcon /> {resource.reading_time}
                      </span>
                    )}
                  </div>
                </div>
              </Card>
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};
