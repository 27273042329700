import { combineReducers } from "redux";
import routeStateSlice from "./routeState/routeState";
import loginStateSlice from "./login/login-reducer";
import onRegistrationSlice from "./onBoarding/onboarding-reducer";
import userEntityStateSlice from "./userInfo/userInfo-reducer";
import subscriptionsStateSlice from "./prices/prices-reducer";
import onSpacesSlice from "./parenting/spaces-reducer";
import ticketsStateSlice from "./tickets/tickets-reducer";
import allPagesStateSlice from "./simplePages/simplePages-reducer";
import wellbeingStateSlice from "./wellbeing/wellbeing-reducer";
import accountSlice from "./userInfo/account-reducer";
import generalOnBoardingSlice from "./generalOnboarding/generalOnboarding-reducer";
import specialTicketsSlice from "./system_tickets/system_ticket-reducer";
import specialResourcesSlice from "./system_resources/system_resource-reducer";
import questionnairesSlice from "./questionnaires/questionnaires.reducer";
import bookmarksSlice from "./bookmarks/bookmarks-reducer";
import extraResourcesSlice from "./extra_resources/extra_resource-reducer";
import waitingListSlice from "./waitingList/waitingList-reducer";
import searchSlice from "./search/search-reducer";
import blogPostsSlice from "./blog/blog.reducer";
import caseStudiesSlice from "./caseStudy/caseStudy.reducer";
import milestonesSlice from "./milestones/milestone-reducer";
import analyticsDataSlice from "./analytics/analytics.reducer";
import onCoachingSlice from "./coaching/coaching.reducer";
import IAtlaSlice from "./atla/atla-reducer";

export const rootReducer = combineReducers({
  onRegistration: onRegistrationSlice,
  onLogin: loginStateSlice,
  userState: userEntityStateSlice,
  routeState: routeStateSlice,
  subscriptions: subscriptionsStateSlice,
  allSpaces: onSpacesSlice,
  pages: allPagesStateSlice,
  tickets: ticketsStateSlice,
  wellbeing: wellbeingStateSlice,
  accountState: accountSlice,
  generalOnBoardingState: generalOnBoardingSlice,
  specialTicketsState: specialTicketsSlice,
  specialResourcesState: specialResourcesSlice,
  questionnairesState: questionnairesSlice,
  bookmarksState: bookmarksSlice,
  extraResources: extraResourcesSlice,
  waitingList: waitingListSlice,
  search: searchSlice,
  blogPostsState: blogPostsSlice,
  caseStudiesState: caseStudiesSlice,
  milestonesState: milestonesSlice,
  analyticsDataState: analyticsDataSlice,
  onCoachingState: onCoachingSlice,
  IAtlaState: IAtlaSlice,
});
