import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_GUIDE,
  CREATE_NEW_CATEGORY,
  DELETE_CATEGORY,
  DELETE_GUIDE,
  GET_ALL_WELLBEING_GUIDES,
  GET_SINGLE_WELLBEING_GUIDE,
  UPDATE_CATEGORY,
  UPDATE_GUIDE,
} from "../config";
import { IInitState } from "../root-interface";
import { getRequestFabric, API_TYPE } from "../thunk-helper";
import { Category } from 'store/categories/categories.interface';
import { Guide } from 'store/system_resources/system_resource.interface';

export const addNewWCategory = createAsyncThunk<
  { message: string; category: Category },
  Category,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/newCategory",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      CREATE_NEW_CATEGORY(),
      rejectWithValue,
      dispatch,
      JSON.stringify(data)
    );
  }
);

export const deleteCategory = createAsyncThunk<
  { message: string; category_id: number },
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteCategory",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_CATEGORY(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateCategory = createAsyncThunk<
  { success: string; category: Category },
  Category,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateCategory",
  async function (data, { getState, rejectWithValue, dispatch }) {
    if (!data.id) return;
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      UPDATE_CATEGORY(data.id),
      rejectWithValue,
      dispatch,
      JSON.stringify(data)
    );
  }
);

// Resources
export const loadGuides = createAsyncThunk<
  Guide[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allGuides",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_WELLBEING_GUIDES(),
      rejectWithValue,
      dispatch
    );
  }
);

export const loadSingleGuide = createAsyncThunk<
  Guide,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/loadSingleGuide",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_SINGLE_WELLBEING_GUIDE(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const addWGuide = createAsyncThunk<
  { success: string; resource: Guide },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/addResource",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      CREATE_GUIDE(),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

export const deleteWGuide = createAsyncThunk<
  number,
  number,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/deleteWGuide",
  async function (id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_GUIDE(id),
      rejectWithValue,
      dispatch
    );
  }
);

export const updateWGuide = createAsyncThunk<
  { success: string; resource: Guide },
  { id: number; data: FormData },
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/updateWGuide",
  async function ({ id, data }, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_GUIDE(id),
      rejectWithValue,
      dispatch,
      data
    );
  }
);
