import { useEffect, useRef, useState } from "react";

import "./ChatBot.module.css";
import { IChatAnswer, IChatPrompt } from "store/atla/atla-interface";
import { Button, ErrorField, Htag } from "components";
import { MessageComponent } from "components/Message/Message.component";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { atlaSendPrompt } from "store/atla/atla-thunk";
import { setUserMessage } from "store/atla/atla-reducer";
import { ThreeDots } from "react-loader-spinner";
import s from "./ChatBot.module.css";

import { Link, useNavigate } from "react-router-dom";

import { downloadAttach } from "controlls/tickets-controll";
import { getArticleLink } from "controlls/use-link";
import cn from "classnames";
import { StackItemComponent } from "components/StackItem/StackItem.component";

const static_questions = [
  "How will the property and money be divided?",
  "What is mediation?",
  "How will the divorce impact my kids?",
];

const Chatbot = () => {
  const [input, setInput] = useState<string>("");

  const formRef = useRef<HTMLFormElement>(null);

  const dispatch = useAppDispatch();
  const lastItemRef = useRef<HTMLDivElement>(null);
  const { messages, loading, error } = useAppSelector(
    (state) => state.IAtlaState
  );
  useEffect(() => {
    if (lastItemRef.current) {
      lastItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end", // Align the bottom of the element
        inline: "end",
      });
    }
  }, [messages]);

  const chatWithGPT3 = async (userInput: string) => {
    const data = {
      message: userInput,
    };

    const answer = dispatch(atlaSendPrompt(data))
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return {
          response: "",
        };
      });

    return answer;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage: IChatPrompt = {
      prompt: input,
      type: "user",
    };

    dispatch(setUserMessage(userMessage));
    chatWithGPT3(input);

    setInput("");
  };

  const handleSendPrompt = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    str: string
  ) => {
    setInput(str);
    if (formRef.current) {
      setTimeout(() => {
        const event = new Event("submit", { bubbles: true, cancelable: true });
        formRef.current?.dispatchEvent(event);
      }, 0);
    }
  };

  const handleDownloadFile = (
    id: number,
    special_ticket_id: number,
    name: string
  ) => {
    downloadAttach(id, special_ticket_id, name);
  };

  return (
    <div className={s.chatbot_container}>
      {messages.length == 0 ? (
        <div className={s.chatbot_messages} ref={lastItemRef}>
          <Htag tag="h1" className="mb32">
            Ask Atla AI
          </Htag>
          <Htag tag="h3" className="mb25">
            Or try a starter question
          </Htag>
          <div className="">
            {" "}
            {static_questions.map((question, index) => (
              <MessageComponent
                key={index}
                onClick={(e) => handleSendPrompt(e, question)}
                className={s.start_questions}
              >
                {question}
              </MessageComponent>
            ))}
          </div>
        </div>
      ) : (
        <div className={s.chatbot_messages} ref={lastItemRef}>
          {messages.map((message: IChatAnswer | IChatPrompt, index: number) => (
            <MessageComponent
              key={index}
              className={`${
                message.type == "user" ? "user-message" : "ai-message"
              }`}
            >
              <Htag tag="h4">
                {message.type == "user" ? "You:" : "Atla AI:"}
              </Htag>
              <pre className="pre">
                {message.type == "user"
                  ? message.prompt
                  : message.answer.response}
              </pre>
              {/*   {message.type !== "user" && (
                <div className={cn(s.chatbot_message__files_wrap, "mb32")}>
                  {message.answer.articleInfo?.attachments?.map(
                    (item, index) => (
                      <StackItemComponent
                        key={index}
                        onClick={() =>
                          handleDownloadFile(
                            item.id,
                            message.answer.articleInfo.id,
                            item.original_name
                          )
                        }
                        data={{
                          title: item.original_name,
                          type: item.type || "sresource",
                        }}
                      ></StackItemComponent>
                    )
                  )}
                </div>
              )} */}
              {message.type !== "user" &&
                message.answer.articleInfo &&
                message.answer.articleInfo.length > 0 && (
                  <div className={cn(s.chatbot_message__files_wrap, "mb32")}>
                    <Htag tag="h4">You can read more about it here:</Htag>
                    {message.answer.articleInfo.map((item, index) => (
                      <Link
                        to={getArticleLink(item.type, item.id, item.space_id)}
                        target="_blank"
                        key={index}
                      >
                        <StackItemComponent
                          data={{
                            title: item.title,
                            type: item.type,
                          }}
                        />
                      </Link>
                    ))}
                  </div>
                )}
            </MessageComponent>
          ))}
          {loading && (
            <MessageComponent>
              <Htag tag="h4">Atla AI: </Htag>
              <ThreeDots />
            </MessageComponent>
          )}
          {error && <ErrorField error={error} />}
        </div>
      )}

      <form
        className={s.chatbot_input_form}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <Htag tag="h4" className="mb15">
          Ask Atla AI
        </Htag>
        <div className={s.formField}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={s.chatBot_request_field}
            disabled={loading}
          />
          <Button apearance="secondary" type="submit" disabled={loading}>
            Ask
          </Button>
        </div>
        <p className="centred">
          <strong className="b2">
            Atla AI can make mistakes. Check important information.
          </strong>
        </p>
      </form>
      {/*     {createPortal(
        <NavLink className={s.backLink} to="/dashboard">
          Back
        </NavLink>,
        document.body
      )} */}
    </div>
  );
};
export default Chatbot;
