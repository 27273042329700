import { createSlice } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";

import { IState } from "./spaces-interface";
import { loadParenting } from "./parenting-thunk";
import { getAllCategories } from "store/categories/categories.thunk";
import { PageType } from "store/system_resources/system_resource.interface";
import { deleteCategory } from "store/wellbeing/wellbeing-thunk";

const initialState: IState = {
  categories: [],
  space_resources: [],
  loading: false,
  error: "",
};

const onSpaces = createSlice({
  name: "Spaces",
  initialState,
  reducers: {
    setChildrenResources: (state, action) => {
      console.log(action.type);
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(loadParenting.fulfilled, (state, action) => {
        state.space_resources = action.payload.space_resources;
      })
      .addCase(getAllCategories.fulfilled, (state: IState, action) => {
        if (!state.categories) return ;
        state.categories = action.payload.filter(
          (guide) => guide.type == PageType.parenting
        );
      })
      .addCase(deleteCategory.fulfilled, (state: IState, action) => {
        state.categories = state.categories.filter(
          (ct) => ct.id !== action.payload.category_id
        );
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error) {
            state.error = `Server error:  ${action.error}`;
          }
        }
      )

      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.loading = false;
          state.error = "";
        }
      );
  },
});
export const { setChildrenResources } = onSpaces.actions;
export default onSpaces.reducer;
