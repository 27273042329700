import { NavLink } from "react-router-dom";
import { TopNav } from "./nav/Nav.component";

import { ReactComponent as MLogo } from "./mobile_logo.svg";
import styles from "./Header.module.css";
import cn from "classnames";
import { HeaderProps } from "./Header.props";
import { Button,  Htag, LogoText } from "components";
import { useEffect, useState } from "react";

import * as yup from "yup";
import {
  useAppDispatch,
  useAppSelector,
  useWindowScrollPositions,
} from "utils/hooks";
import {
  setInterviewToInit,
  setRegModalStatus,
} from "store/onBoarding/onboarding-reducer";
import { Banner, getSB } from "store/widgets/smartbanner-thunk";
import { Helmet } from "react-helmet";

export const validationSupportForm = yup.object({
  name: yup
    .string()
    .min(2, "Name should be at least 2 characters length")
    .required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const Header = ({ data, ...props }: HeaderProps): JSX.Element => {
  const { scrollY } = useWindowScrollPositions();
  const userToken = useAppSelector((state) => state.userState.token);
  const [hideLogin, setHideLogin] = useState(false);
  const dispatch = useAppDispatch();
  const [smartBanner, setSmartBanner] = useState<Banner>();

  const regModalStatus = useAppSelector(
    (state) => state.onRegistration.openModal
  );
  const handleOpenReg = () => dispatch(setRegModalStatus(true));

  const handleCloseReg = () => {
    dispatch(setInterviewToInit());

    dispatch(setRegModalStatus(false));
  };

  useEffect(() => {
    dispatch(getSB())
      .unwrap()
      .then((resp) => {
        setSmartBanner(resp);
      })
      .catch((err) => {
        console.log("Error");
      });
  }, []);

  useEffect(() => {
    if (userToken) {
      setHideLogin(true);
    } else {
      setHideLogin(false);
    }
  }, []);

  return (
    <header
      className={cn(styles.header, {
        [styles.sticky]: scrollY > 100,
        [styles.middleOffice]: data.variant === "middleOffice",
        [styles.frontOffice]: data.variant === "frontOffice",
        [styles.blogLayout]: data.variant == "blog",
        [styles.pricing_header]: location.pathname.includes("/pricing"),
      })}
      {...props}
    >
      <Helmet>
        <title>Online Divorce Platform | SeparateSpace</title>
        <meta
          name="description"
          content="SeparateSpace - Your trusted partner for navigating separation and divorce. Find resources, tools, and support to manage your separation journey effectively."
        />
      </Helmet>
      <div
        className={cn(styles.wrapper, {
          [styles.mobile_heading]: !data.isHome,
        })}
      >
        <div className={cn(styles.topHeader)}>
          {smartBanner?.visible && (
            <a href={smartBanner.url} className={styles.smartBanner}>
              <span>{smartBanner.text}</span>
            </a>
          )}
          <div className={styles.topHeaderNav}>
            <NavLink className={styles.logo} to="/">
              <LogoText className={styles.desktopLogo} />
              <MLogo className={styles.mobileLogo} />
            </NavLink>

            <TopNav className={styles.nav} />
          </div>
        </div>
        <div className={cn(styles.headling)}>
          {data.isHome ? (
            <>
              {data.title && <h1 className={styles.heroTitle}>{data.title}</h1>}
              {data.isHome && (
                <p className={styles.heroDesc}>
                  The platform that gives personalised step-by-step legal
                  guidance so you can move forward (and save on legal fees).
                </p>
              )}
              {!hideLogin && (
                <div className={styles.groupButton}>
                  <Button onClick={handleOpenReg} apearance="secondary">
                    Sign up
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              {data.title && (
                <Htag tag="h3" className={styles.heroTitle}>
                  {data.title}
                </Htag>
              )}
              <p>{data.text}</p>
            </>
          )}
        </div>
        <div className={cn(styles.heroImg, "mobile_hide")}>
          <img src={data.img} alt="" />
        </div>
      </div>
    </header>
  );
};
