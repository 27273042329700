import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGOUT } from "store/config";
import { IInitState } from "store/root-interface";
import { API_TYPE, getRequestFabric } from "store/thunk-helper";

export const logoutAction = createAsyncThunk<
  { message: string },
  undefined,
  { getState: IInitState; rejectValue: string }
>("@@logout", async function (_, { getState, rejectWithValue, dispatch }) {
  const { userState } = getState() as IInitState;

  const header = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userState.token,
      responseType: "json",
      "Content-Type": "application/json",
    },
    body: null,
  };
  return await getRequestFabric(
    API_TYPE.POST,
    getState,
    LOGOUT(),
    rejectWithValue,
    dispatch,
    null
  );
});
