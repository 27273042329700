import { useMemo } from "react";

type GroupByResult<T> = Record<string | number, T[]>;

const useGroupBy = <T, K extends keyof T>(
  array: T[],
  field: K
): GroupByResult<T> => {
  return useMemo(() => {
    if (!Array.isArray(array) || !field) return {};

    return array.reduce<GroupByResult<T>>((acc, item) => {
      const key = item[field] as string | number;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  }, [array, field]);
};

export default useGroupBy;
