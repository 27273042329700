import { createSlice, createAction } from "@reduxjs/toolkit";
import { changePassAction, forgotPassAction } from "./forgotPass-thunk";

export const resetToDefault = createAction("root/reset-app");

const initialLoginState = {
  error: "",
  openLoginModal: false,
  openPassModal: false,
};

export const loginState = createSlice({
  name: "@@login",
  initialState: initialLoginState,
  reducers: {
    openLoginModal: (state, action) => {
      state.openLoginModal = action.payload;
    },
    openPassModal: (state, action) => {
      state.openPassModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        localStorage.removeItem("userToken");

        return initialLoginState;
      })
      .addCase(forgotPassAction.rejected, (state, action) => {
        if (action.payload) state.error = action.payload;
      })
      .addCase(changePassAction.rejected, (state, action) => {
        if (action.payload) state.error = action.payload;
      });
  },
});

export const { openLoginModal, openPassModal } = loginState.actions;

export default loginState.reducer;
