export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export const LOGIN = () => `${BASE_URL}/login`;
export const LOGOUT = () => `${BASE_URL}/logout`;
export const SIGNUP = () => `${BASE_URL}/register`;
export const SIGNOUT = () => `${BASE_URL}/logout`;
export const FORGO_PASS = () => `${BASE_URL}/password/email`;
export const RESET_PASS = () => `${BASE_URL}/password/reset`;
export const ISITRIGHT = () => `${BASE_URL}/clarification/send`;
export const VERIFY_EMAIL = () => `${BASE_URL}/email/verify`;
export const RESEND_VERIFY_EMAIL = () => `${BASE_URL}/email/resend`;

export const MEMORY_CREATE = () => `${BASE_URL}/field/remember`;
export const MEMORY_DELETE = (field_id: string) =>
  `${BASE_URL}/field/${field_id}/forget`;

// InstagramFeed
export const GET_POSTS = () => `${BASE_URL}/feeds`;

// Stripe

export const CANCEL_STRIPE = () => `${BASE_URL}/stripe/subscription/cancel`;

export const RESUME_STRIPE = () => `${BASE_URL}/stripe/subscription/resume`;
export const REFUND_REQUEST_STRIPE = () => `${BASE_URL}/stripe/refund`;
export const REFUNDS_LIST_STRIPE = (page: number, limit: number) =>
  `${BASE_URL}/admin/refunds?page=${page}&limit=${limit}`;
export const REFUND_RELOAD_STRIPE = (refund_id: string) =>
  `${BASE_URL}/admin/refunds/${refund_id}/run`;
export const REFUND_DELETE_STRIPE = (refund_id: string) =>
  `${BASE_URL}/admin/refunds/${refund_id}/delete`;

// onboarding

export const GENERAL_ONBG = () => `${BASE_URL}/user/general-questionnaire/save`; // SAVE DATA
export const CHILDREN_ONBG = () => `${BASE_URL}/user/children-onboarding/save`;

// Spaces

export const SET_SPACE_STATUS = (status_id: number) =>
  `${BASE_URL}/space-status/${status_id}/completed`;
export const GET_CHILDREN_SPACE = () => `${BASE_URL}/space/children`;
//Milestones
export const GET_ALL_MILESTONES = () => `${BASE_URL}/milestones/`;
export const UPDATE_TIMELINE = (timeline_id: number) =>
  `${BASE_URL}/admin/timeline/${timeline_id}/update/`;
export const GET_MILESTONE = (timeline_id: number) =>
  `${BASE_URL}/tickets/${timeline_id}/`;

//Tickets
export const ALL_TICKETS = (space: number) =>
  `${BASE_URL}/user-tickets/${space}`;

export const GET_SINGLE_TICKET = (id: string) => `${BASE_URL}/ticket?${id}`;
export const CREATE_TICKET = (space_id: number | undefined) =>
  `${BASE_URL}/ticket/create/${space_id}`;
export const UPDATE_TICKET = (ticket_id: number) =>
  `${BASE_URL}/ticket/${ticket_id}/update
`;
export const SET_TICKET_STATUS = (ticket_id: number | undefined) =>
  `${BASE_URL}/ticket/${ticket_id}/toggle-status`;

export const DELETE_TICKET = (ticket_id: number | undefined) =>
  `${BASE_URL}/ticket/${ticket_id}/delete`;
export const CREATE_COMMENT = () => `${BASE_URL}/comment/create`;
export const UPDATE_COMMENT = (comment_id: number) =>
  `${BASE_URL}/comment/update/${comment_id}`;
export const DELETE_COMMENT = (comment_id: number) =>
  `${BASE_URL}/comment/delete/${comment_id}`;

//Pages - article

export const CREATE_PAGE_TEMPLATE = () => `${BASE_URL}/admin/page/create`;
export const UPDATE_PAGE_TEMPLATE = (page_id: number) =>
  `${BASE_URL}/admin/page/update/${page_id}`;
export const DELETE_PAGE_TEMPLATE = (page_id: number) =>
  `${BASE_URL}/admin/page/remove/${page_id}`;
export const GET_ALL_PAGES = () => `${BASE_URL}/pages`;
export const GET_PAGE = (page_id: number) => `${BASE_URL}/page/${page_id}`;

// Wellbeing
export const GET_ALL_CATEGORIES = () => `${BASE_URL}/categories`;
export const CREATE_NEW_CATEGORY = () => `${BASE_URL}/admin/category/create`; // Create new challenge
export const UPDATE_CATEGORY = (id: number) =>
  `${BASE_URL}/admin/category/update/${id}`;
export const DELETE_CATEGORY = (id: number) =>
  `${BASE_URL}/admin/category/delete/${id}`;


//System wellbeing resources
export const GET_SYSTEM_WELLBEING_GUIDES = () =>
  `${BASE_URL}/admin/wellbeing/resources/system`;
export const ADD_WELLBEING_SYSTEM_GUIDE = () =>
  `${BASE_URL}/wellbeing/resources/system/addition`;

// Feelings
export const GET_ALL_FEELLINGS = () => `${BASE_URL}/wellbeing/feelings`;
export const UPDATE_FEELING = (feeling_id: number) =>
  `${BASE_URL}/admin/wellbeing/feeling/${feeling_id}/update`;
export const SET_FIRST_ACCESS = () => `${BASE_URL}/user/feelings/visible`;

// Tools
export const GET_ALL_TOOLS = () => `${BASE_URL}/wellbeing/tools`;
export const GET_SINGLE_WELLBEING_TOOL = (tool_id: number) =>
  `${BASE_URL}/wellbeing/tool/${tool_id}`;
export const CREATE_TOOL = () => `${BASE_URL}/admin/wellbeing/tool/create`;
export const UPDATE_TOOL = (tool_id: number) =>
  `${BASE_URL}/admin/wellbeing/tool/${tool_id}/update`;
export const DELETE_TOOL = (tool_id: number) =>
  `${BASE_URL}/admin/wellbeing/tool/${tool_id}/delete`;
export const VIEWED_TOOL = (tool_id: number) =>
  `${BASE_URL}/user/tool/${tool_id}/visible`;

// Resources
export const GET_ALL_WELLBEING_GUIDES = () => `${BASE_URL}/wellbeing/resources`;
export const GET_SINGLE_WELLBEING_GUIDE = (id: number) =>
  `${BASE_URL}/wellbeing/resource/${id}`;
export const CREATE_GUIDE = () =>
  `${BASE_URL}/admin/wellbeing/resource/create`;
export const UPDATE_GUIDE = (id: number) =>
  `${BASE_URL}/admin/wellbeing/resource/${id}/update`;
export const DELETE_GUIDE = (resource_id: number) =>
  `${BASE_URL}/admin/wellbeing/resource/${resource_id}/delete`;


// System tickets
export const ADD_SYSTEM_TICKETS = () => `${BASE_URL}/tickets/special/addition`;
export const GET_SYSTEM_TICKETS = () => `${BASE_URL}/admin/special-tickets`;
export const GET_SINGLE_SYSTEM_TICKET = (special_ticket_id: number) =>
  `${BASE_URL}/admin/special-ticket/${special_ticket_id}`;
export const UPDATE_SYSTEM_TICKET = (system_ticket: string) =>
  `${BASE_URL}/admin/special-ticket/${system_ticket}/update`;

// System Resources
export const ADD_SYSTEM_RESOURCES = () =>
  `${BASE_URL}/space-resources/addition`;
export const GET_SYSTEM_RESOURCES = (type: number) => `${BASE_URL}/space-resources?type=${type}`;
export const GET_SPACE_RESOURCES = () =>
  `${BASE_URL}/space-resources/recent-children`;
export const GET_SINGLE_RESOURCE = (system_resource: number) =>
  `${BASE_URL}/space-resource/${system_resource}`;
export const UPDATE_SYSTEM_RESOURCE = (system_resource: string) =>
  `${BASE_URL}/admin/space-resource/${system_resource}/update`;
// Socket

export const SOCKET_CONNECT = () => `${SOCKET_BASE_URL}`;

// User

export const CHANGE_PASS = () => `${BASE_URL}/user/password`;
export const GET_USER_DATA = () => `${BASE_URL}/is-user-logged`;
export const UPDATE_PROFILE = () => `${BASE_URL}/user/update`;
export const DELETE_PROFILE = () => `${BASE_URL}/user/delete`;
export const EMAIL_SUBSCRIPTION = () => `${BASE_URL}/user/subscription`;

// Bookmarks
export const GET_ALL_BOOKMARKS = () => `${BASE_URL}/bookmarks`;
export const ADD_BOOKMARK = () => `${BASE_URL}/bookmark/create`;
export const REMOVE_BOOKMARK = () => `${BASE_URL}/bookmark/delete`;

// Extra Resources
export const GET_ALL_EXTRA_RESOURCES = () => `${BASE_URL}/articles`;
export const GET_EXTRA_RESOURCE = (id: number) => `${BASE_URL}/article/${id}`;
export const ADD_EXTRA_RESOURCES = () => `${BASE_URL}/admin/article/create`;
export const EDIT_EXTRA_RESOURCES = (id: string) =>
  `${BASE_URL}/admin/article/${id}/update`;
export const DELETE_EXTRA_RESOURCES = (id: number) =>
  `${BASE_URL}/admin/article/${id}/delete`;

// Get reacent resources
export const GET_RESENT_RESOURCES = () => `${BASE_URL}/space-resources/recent`;

// waiting List
export const GET_WAITING_LIST = () => `${BASE_URL}/admin/applicants`;
export const GET_SUBSCRIBERS_LIST = () => `${BASE_URL}/admin/users`;
export const GET_SUBSCRIBER = (id: number) => `${BASE_URL}/admin/user/${id}`;

export const DELETE_MEMBER = (user_id: number) =>
  `${BASE_URL}/admin/user/remove?user_id=${user_id}`;

// Search
export const GET_ALL_RESULTS = (search: string) =>
  `${BASE_URL}/search?search=${search}`;

// Attachments
export const DELETE_ATTACH = (id: number) =>
  `${BASE_URL}/admin/attachment/${id}/delete`;
export const DELETE_TICKET_ATTACH = (id: number) =>
  `${BASE_URL}/admin/special-ticket/attachment/delete/${id}`;
export const DOWNLOAD_ATTACH = (id: number) =>
  `${BASE_URL}/attachment/${id}/download`;
export const DOWNLOAD_TICKET_ATTACH = (id: number, ticket_id: number) =>
  `${BASE_URL}/ticket/attachment/download?special_ticket_id=${ticket_id}&attachment_id=${id}`;

// Blog

export const GET_ALL_POSTS = (page: number, limit: number) =>
  `${BASE_URL}/blog/list?page=${page}&limit=${limit}`;
export const GET_SINGLE_POST = (page_id: string) =>
  `${BASE_URL}/blog/${page_id}`;
export const CREATE_POST = () => `${BASE_URL}/admin/blog/create`;
export const UPDATE_POST = (page_id: string) =>
  `${BASE_URL}/admin/blog/${page_id}/update`;
export const DELETE_POST = (slug: string) =>
  `${BASE_URL}/admin/blog/${slug}/remove`;
export const GET_RECENT_POSTS = (post_id: number) =>
  `${BASE_URL}/blog/recent?id=${post_id}`;

// Case Studies

export const GET_ALL_CS = () => `${BASE_URL}/case-studies/`;
export const GET_USER_SP_CS = () => `${BASE_URL}/user-case-studies/`;
export const GET_ALL_SP_CS = () => `${BASE_URL}/admin/case-studies-special/`;

export const GET_CS = (case_study_id: string) =>
  `${BASE_URL}/case-study/${case_study_id}`;
export const CREATE_CS = () => `${BASE_URL}/admin/case-study/create`;
export const UPDATE_CS = (case_study_id: string) =>
  `${BASE_URL}/admin/case-study/${case_study_id}/update`;
export const DELETE_CS = (case_study_id: number) =>
  `${BASE_URL}/admin/case-study/${case_study_id}/delete
`;
export const ADD_CS_KEYS = () =>
  `${BASE_URL}/case-study-addition
`;

// Analytics
export const GET_ANALITIC_DATA = () => `${BASE_URL}/admin/statistic`;
export const GET_USERS_DATA_STATISTIC = (time: number) =>
  `${BASE_URL}/admin/user-statistic?last_days=${time}`;
export const GET_USERS_DATA_ALL_STATISTIC = (time: number) =>
  `${BASE_URL}/admin/user-statistic-progress?last_days=${time}`;

export const GET_ACTIVITIES_DATA = () => `${BASE_URL}/admin/analytics`;

export const RSS_SEND = () => `${BASE_URL}/rss/send`;

export const GET_BOOKED_SESSION_PROGRESS = (time: number) =>
  `${BASE_URL}/admin/booked-session-progress?last_days=${time}`;

// Smart Banner
export const SB_GET = () => `${BASE_URL}/banner/show`;
export const SB_UPDATE = () => `${BASE_URL}/admin/banner/update`;
export const SB_CLEAR = () => `${BASE_URL}/admin/banner/clear`;

// experts

export const SEND_EBOOKING = () => `${BASE_URL}/call-to-expert`;

// ATLA BOT
export const ATLABOTAPI = () => `https://ss-backend.azurewebsites.net/chat`;
export const GETFILESFORAPI = () => `${BASE_URL}/workaround`;
