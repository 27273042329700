import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { Accordion, Button, ErrorField, Htag } from "components";
import s from "./GeneralOnboarding.module.css";
import { FullModalStyled } from "components/FullWidthModal/FullModal.component";
import { useState } from "react";
import { addId, removeId } from "store/system_tickets/system_ticket-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import * as yup from "yup";
import { useFormik } from "formik";

import { QuestionnaireProps } from "pages/Dashboard/Parenting/CQuestionnaire/Questionnaire.props";
import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";

const accordionData = [
  {
    title: `Why are we asking this? `,
    content: `<p>We know that the time between deciding to separate and finalising your divorce and financial separation is really difficult.  We can help with the immediate arrangements - that might be tips on how to manage the living arrangements while you’re living with your ex.  For others, it might be working out who pays the heating bill for a property that only one of you is living in.  
</p>`,
  },
];

const validationForm4 = yup.object({
  living_property: yup.string().nullable().required("Please select an option."),
});

export const FormGB4 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );
  const [different, setDifferent] = useState(false);
  const [together, setTogether] = useState(false);
  const [addFS, setAddFS] = useState(false);

  const handleClose = () => {
    setDifferent(false);
    setTogether(false);
    setAddFS(false);
  };

  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm4,
    onSubmit: () => {
      handleNextStep();
    },
  });

  const updateInterview = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDifferent(value === "different");
    setTogether(value === "same");
    dispatch(removeId(["F1"]));
    dispatch(removeResourceId("NG3"));

    if (value === "different") {
      dispatch(removeResourceId("FR2A"));
    } else {
      dispatch(addResourceId("FR2A"));
    }

    formik.handleChange(event);
    setData(name, value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddFS(event.target.checked);
    dispatch(removeId("F1"));
    dispatch(removeResourceId("NG3"));

    if (event.target.checked) {
      dispatch(addId(["F1"]));
      dispatch(addResourceId("NG3"));
    }
  };

  return (
    <form id="form-4" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>
      <Htag tag="h3">
        What best describes your living arrangements at the moment?
      </Htag>

      <div className={s.form_oneline}>
        <div className={s.formField}>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="living_property"
            className={s.radioboxes}
          >
            <FormControlLabel
              value="different"
              checked={formik.values.living_property == "different"}
              control={<Radio color="default" onChange={updateInterview} />}
              label="My ex and I are living in different properties."
            />
            <FormControlLabel
              value="same"
              checked={formik.values.living_property == "same"}
              control={<Radio color="default" onChange={updateInterview} />}
              label="My ex and I are living in the same property."
            />
          </RadioGroup>
          {formik.touched.living_property && formik.errors.living_property && (
            <ErrorField error={formik.errors.living_property} />
          )}
        </div>
      </div>

      <Accordion className={s.desc} accordionData={accordionData} />
      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
      <FullModalStyled open={different} close={handleClose} color="dark">
        <div className={s.firstSlide}>
          <p>
            If you have moved out of an owned property that you’ve shared as a
            matrimonial home, you may need to know about Matrimonial Home
            Rights. You may need to take action urgently.
          </p>
          <p className={s.flexLine}>
            <Checkbox
              className={s.checkbox}
              checked={addFS}
              onChange={handleCheckboxChange}
              size="small"
              color="default"
            />{" "}
            Click here if you want information on your choices.
          </p>
          <Accordion
            className={s.desc}
            accordionData={[
              {
                title: "What are Matrimonial Home Rights?",
                content: `<p> Matrimonial home rights are a number of rights that a spouse or civil partner has in relation to the family home if their ex owns it.</p>`,
              },
            ]}
          />
          <div className={s.groupButton}>
            <Button
              onClick={handleClose}
              className={s.dark}
              apearance="secondary"
            >
              Ok
            </Button>
          </div>
        </div>
      </FullModalStyled>
      <FullModalStyled open={together} close={handleClose} color="dark">
        <div className={s.firstSlide}>
          <p>
            If you move out of an owned property that you’ve shared as a
            matrimonial home, you may need to know about Matrimonial Home
            Rights. If you’re planning to move out soon, you may need to take
            action.{" "}
          </p>
          <p className={s.flexLine}>
            <Checkbox
              checked={addFS}
              className={s.checkbox}
              onChange={handleCheckboxChange}
              size="small"
              color="default"
            />{" "}
            Click here if you want information on your choices
          </p>
          <Accordion
            className={s.desc}
            accordionData={[
              {
                title: "What are Matrimonial Home Rights?",
                content: `<p> Matrimonial home rights are a number of rights that a spouse or civil partner has in relation to the family home if their ex owns it.</p>`,
              },
            ]}
          />

          <div className={s.groupButton}>
            <Button
              onClick={handleClose}
              className={s.dark}
              apearance="secondary"
            >
              Ok
            </Button>
          </div>
        </div>
      </FullModalStyled>
    </form>
  );
};
