import { useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, IconButton } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Htag } from "components";
import { get_system_tickets } from "store/system_tickets/system-ticket-thunk";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

export const ListSystemTickets = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const system_tickets = useAppSelector(
    (state) => state.specialTicketsState.tickets_db
  );
  const copy = async (id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/resources/${id}`
    );
  };

  const preview = async (id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/resources/${id}`
    );
  };

  useEffect(() => {
    dispatch(get_system_tickets());
  }, [dispatch]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "unique_number",
      headerName: "Unique number",
      headerClassName: s.headerClassName,
      width: 120,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "milestone_id",
      headerClassName: s.headerClassName,
      headerName: "Milestone",
      sortable: false,
      width: 70,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className={s.actions}>
            <NavLink to={`${pathname}/edit-task/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>

            <NavLink to={`${pathname}/edit-task/${params.row.id}/preview`}>
              <IconButton aria-label="edit" color="info">
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </NavLink>

        {/*     <ContentCopyIcon
              className={s.externalLink}
              onClick={() =>
                copy(params.row.id.toString())
              }
            /> */}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h2" className="mb25">
        List of system tickets
      </Htag>
      <Box sx={{ height: 875, width: "100%" }}>
        <DataGrid
          rows={system_tickets}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 15, page: 0 },
            },
          }}
          className={s.tableWrap}
          pageSizeOptions={[15, 30, 50, 70, 100]}
        />
      </Box>
    </>
  );
};
