import { createSlice } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import {
  createNewTicket,
  deleteTicket,
  loadMilestoneTickets,
  loadSingleTicket,
  loadTickets,
  setNewTicketStatus,
} from "./tickets-thunk";
import { getBoards, getTamplates } from "./tickets-selectors";
import { ITState } from "./tickets-interface";

const initialState: ITState = {
  tickets: [],
  boards: [],
  templates: [],
  loading: false,
  error: "",
};

const ticketsState = createSlice({
  name: "@@tickets",
  initialState,
  reducers: {
    setBoards: (state, action) => {
		
      state.boards = getBoards(action.payload);
    },
    addToEndBoard: (state: ITState, action) => {
      state.tickets.map((el) => {
        if (el.id == action.payload.ticket_id) {
          el.sort = action.payload.sort + 1;
          el.status = action.payload.status;
        }
        return el;
      });
      state.boards = getBoards(state.tickets);
    },

    dragDrop: (state, action) => {
      state.boards = state.boards.map((b) => {
        if (b.id === action.payload.id) {
          return action.payload;
        }
        return b;
      });
    },

    removeTicket: (state, action) => {
      state.tickets = state.tickets.filter(
        (item) => item.id !== action.payload
      );
    },
    updateTicketInfo: (state, action) => {
      state.tickets = state.tickets.map((el) => {
        if (el.id == action.payload.id) {
          return action.payload;
        }

        return el;
      });
      state.boards = getBoards(state.tickets);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(loadTickets.pending, (state) => {
        state.tickets = [];
        state.boards = [];
        state.templates = [];
        state.loading = true;
      })
      .addCase(loadMilestoneTickets.pending, (state) => {
        state.tickets = [];
        state.boards = [];
        state.templates = [];
        state.loading = true;
      })

      .addCase(loadTickets.fulfilled, (state, action) => {
        state.tickets = action.payload[0].tickets.filter(
          (tiket) => tiket.special_ticket_id
        );
        state.boards = getBoards(state.tickets);
        state.templates = getTamplates(state.tickets);
      })
      .addCase(loadMilestoneTickets.fulfilled, (state, action) => {
        state.tickets = action.payload.filter(
          (tiket) => tiket.special_ticket_id
        );
        state.boards = getBoards(state.tickets);

        state.templates = getTamplates(state.tickets);
      })
      .addCase(loadSingleTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadSingleTicket.fulfilled, (state, action) => {
        state.tickets = state.tickets.filter((item) => {
          return (
            item.id !== action.payload.id && action.payload.special_ticket_id
          );
        });
        state.tickets.push(action.payload);
        state.boards = getBoards(state.tickets);
      })
      .addCase(deleteTicket.fulfilled, (state, action) => {
        state.tickets = state.tickets.filter((item) => {
          return item.id !== action.payload.ticket_id;
        });

        state.boards = getBoards(state.tickets);
      })
      .addCase(setNewTicketStatus.fulfilled, (state, action) => {
        state.boards = state.boards.map((board, index) => {
          if (index == action.payload.status) {
            board.tickets = board.tickets.map((item) => {
              if (item.id == action.payload.id) {
                return action.payload;
              }
              return item;
            });
          }

          return board;
        });
      })
      .addCase(createNewTicket.fulfilled, (state, action) => {
        const ticket = { ...action.payload.ticket, editable: 1 };
        state.tickets.push(ticket);
        state.boards = getBoards(state.tickets);
      })
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = `Server error:  ${action.error.message}`;
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export const {
  dragDrop,
  setBoards,
  removeTicket,
  addToEndBoard,
  updateTicketInfo,
} = ticketsState.actions;

export default ticketsState.reducer;
