import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./DivorceStep.module.css";
import { useEffect, useState } from "react";

import { Milestone } from "store/milestones/milestones-interface";
import { loadMilestones } from "store/milestones/milestones-thunk";
import { loadMilestoneTickets } from "store/tickets/tickets-thunk";
import { get_user_all_cs } from "store/caseStudy/caseStudy_thunk";
import { ArrowLeftIcon, Htag, Notification } from "components";
import TimeLineItem from "components/TimelineItem/TimeLineItem.component";
import { BoardsComponent } from "components/Boards/Boards.component";
import { CaseStudyList } from "../CaseStudies/CaseStudyList.component";
import { ResourcesListComponent } from "components/SResources/ResourcesList.component";
import { TemplatesComponent } from "components/Templates/Templates.component";
import { Guide } from "store/system_resources/system_resource.interface";
import { createSelector } from "@reduxjs/toolkit";

const selectMilestoneMemory = createSelector(
  [
    (state: any) => state.userState.user.memory,
    (_: any, id: string | undefined) => id,
  ],
  (memory, id) =>
    memory.filter(
      (item: { name: string }) => item.name === `Notification_Milestone_${id}`
    )
);

const NOTIFICATION_TEXT = [
  "You're in Step 1 and it's all about getting organised. Click on a task to find out more about it. Once you’ve completed a task, move it over to the 'completed' column. We've also put together a personalised package of guides and templates to help you.",
  "During Step 2, you’ll progress your divorce online. It can feel intense dealing with the official process. We’ve got lots of support to help you navigate this with confidence. Your personalised package of guides and templates has been updated for this stage.",
  "During Step 3, you’ll get an understanding of your financial landscape. Some people find this step overwhelming, while others appreciate the clarity. We’re here to help either way. ",
  "During Step 4, you’ll negotiate finances with your ex. Many people find this to be the hardest part of divorce. We’ve got templates to help you communicate through difficult moments.",
  "During Step 5, you’ll do all the paperwork to officially get divorced and formalise your financial separation. There’s a lot of admin, and it’s important to get it right. We’ve organised and explained everything for you. You’re nearly there!",
  "During Step 6, you’ll action your financial agreement with your ex and do final tasks like updating your Will. We know how tiring this can be, but completing this final step will help you enter a new chapter with everything sorted.",
];

export const DivorceStep = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [resources, setResources] = useState<Guide[]>([]);
  const milestoneMemory = useAppSelector((state) =>
    selectMilestoneMemory(state, id)
  );

  const { milestones } = useAppSelector((state) => state.milestonesState);
  const [currentMilestone, setCurrentMilestone] = useState<Milestone>();
  const caseStudies = useAppSelector(
    (state) => state.caseStudiesState.caseStudiesArray
  );

  useEffect(() => {
    dispatch(loadMilestones())
      .unwrap()
      .then((res: Milestone[]) => {
        const currentmilestone = res.filter(
          (item) => item.id.toString() == id
        )[0];

        dispatch(loadMilestoneTickets(Number(id)));
        if (currentmilestone) {
          setCurrentMilestone(currentmilestone);
          setResources(currentmilestone.resources);
        }
      });
    dispatch(get_user_all_cs());
  }, [id]);

  return (
    <>
      <Htag tag="h1" className="mb32">
        Step {currentMilestone?.id}: {currentMilestone?.title}
      </Htag>

      <section className={s.milestones}>
        {milestones.map((milestone, index) => (
          <TimeLineItem
            index={index + 1}
            id={milestone.id.toString()}
            title={milestone.title}
            key={index}
            completed_percent={milestone.complete_percent}
          />
        ))}
      </section>
      {id && milestoneMemory[0]?.value !== "true" && (
        <Notification name={`Milestone_${id}`}>
          {id && NOTIFICATION_TEXT[Number(id) - 1]}
        </Notification>
      )}
      <BoardsComponent />
      {id &&
        caseStudies &&
        caseStudies.length > 0 &&
        (Number(id) == 3 || Number(id) == 4) && (
          <section className={s.guidesWrap}>
            <div className={s.resources_header}>
              <Htag tag="h2" className="mb10">
                Case studies
              </Htag>
              <p>
                Each case study highlights multiple options for how these
                families approached their separation.
              </p>
            </div>

            <CaseStudyList data={caseStudies} />
          </section>
        )}
      <section className={s.guidesWrap}>
        <header className={s.resources_header}>
          <Htag tag="h2">Guides</Htag>
        </header>
        {resources && (
          <ResourcesListComponent
            show_in_row={4}
            resources={resources}
            showTgas={true}
          />
        )}
      </section>
      <section className={s.guidesWrap}>
        <TemplatesComponent />
      </section>

      {id && Number(id) < 5 && (
        <div className={s.nextStep}>
          <NavLink to={`/dashboard/milestone/${Number(+id + 1)}`}>
            Next step <ArrowLeftIcon />
          </NavLink>

          <p>{milestones[Number(id)]?.title}</p>
        </div>
      )}
    </>
  );
};
