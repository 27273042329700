import { FirstScreenChildren } from "./FirstScreenChildren.component";

import cn from "classnames";
import s from "./Questionnaire.module.css";

import { Stepper } from "components";
import { FormCB3 } from "./FormCB3.component";
import { FormCB2 } from "./FormCB2.component";
import { FormCB1 } from "./FormCB1.component";
import { FormCB4 } from "./FormCB4.component";
import { FormCB5 } from "./FormCB5.component";

import {
  setBoardingStep,
  setCQuestionnaire,
  setPrevBoardingStep,
} from "store/questionnaires/questionnaires.reducer";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { IDebs } from "store/generalOnboarding/generalOnboarding-interface";

export const CQuestionnaire = ({ close }: { close?: () => void }) => {
  const dispatch = useAppDispatch();
  const { cQuestionnaire, activeStep } = useAppSelector(
    (state) => state.questionnairesState
  );

  const handleBack = useCallback(() => {
    dispatch(setPrevBoardingStep(activeStep));
  }, [activeStep, dispatch]);

  const setFQuestionnaireData = (name: string, value: string | IDebs[]) => {
    dispatch(
      setCQuestionnaire({
        ...cQuestionnaire,
        [`${name}`]: value,
      })
    );
  };

  const handleNextStep = () => {
    dispatch(setBoardingStep(activeStep));
  };
  const stepContext = [
    <FirstScreenChildren
      setData={setFQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormCB1
      setData={setFQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormCB2
      setData={setFQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
      close={close}
    />,
    <FormCB3
      setData={setFQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
    <FormCB4
      setData={setFQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,

    <FormCB5
      close={close}
      setData={setFQuestionnaireData}
      handleBack={handleBack}
      handleNextStep={handleNextStep}
    />,
  ];

  return (
    <>
      <div className={cn(s.onboard_item)}>{stepContext[activeStep]}</div>

      {activeStep > 0 && (
        <Stepper
          steps={stepContext.length }
          activeStep={activeStep }
          className={s.stepperPosition}
        />
      )}
    </>
  );
};
