import { configureStore, isPlain } from "@reduxjs/toolkit";
import { rootReducer } from "./root-reducer";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Iterable } from "immutable";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "userState",
    "pages",
    "wellbeing",
    "tickets",
    "caseStudiesState",
    "bookmarksState",
    "subscriptions",
    "parentingState",
    "specialResourcesState",
  ],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const actionSanitizer = (action: any) => {
  const uiRouterActions = /@ui-router.+/g;
  return uiRouterActions.test(action.type)
    ? {
        type: action.type,
        transition: sanitizeUIRouterTransition(action.transition),
      }
    : action;
};
const stateSanitizer = (state: any): any => {
  if (state.router && state.router.last && state.router.last) {
    return {
      ...state,
      router: sanitizeUIRouterTransition(state.router.last),
    };
  }
  return state;
};

const sanitizeUIRouterTransition = (transition: any): any => ({
  params:
    transition.router &&
    transition.router.globals &&
    transition.router.globals.params,
  current:
    transition.router &&
    transition.router.globals &&
    transition.router.globals.current,
  targetState: transition.targetState && transition.targetState().state(),
  from: transition.from && transition.from(),
  to: transition.to && transition.to(),
});
const reduxDevtoolsExtensionOptions = {
  actionSanitizer,
  stateSanitizer,
};

const isSerializable = (value: any) =>
  Iterable.isIterable(value) || isPlain(value);

export const store = configureStore({
  reducer: persistedReducer,

  devTools: reduxDevtoolsExtensionOptions,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        isSerializable,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      actionSanitizer,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
