import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ALL_CATEGORIES } from 'store/config';
import { IInitState } from 'store/root-interface';
import { API_TYPE, getRequestFabric } from 'store/thunk-helper';
import { Category } from './categories.interface';


export const getAllCategories = createAsyncThunk<
  Category[],
  undefined,
  { getState: IInitState; rejectValue: string }
>(
  "@@wellbeing/allCategories",
  async function (_, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.GET,
      getState,
      GET_ALL_CATEGORIES(),
      rejectWithValue,
      dispatch
    );
  }
);
