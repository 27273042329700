import s from "./GeneralOnboarding.module.css";
import { Accordion, Button, ErrorField, Htag } from "components";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useCallback } from "react";
import { addId, removeId } from "store/system_tickets/system_ticket-reducer";
import { QuestionnaireProps } from "pages/Dashboard/Parenting/CQuestionnaire/Questionnaire.props";

import { useAppDispatch, useAppSelector } from "utils/hooks";

import { useFormik } from "formik";

import * as yup from "yup";
import { setBoardingStep } from "store/generalOnboarding/generalOnboarding-reducer";
import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";

const NO = "no";
const APPLICATION = "application";
const FIRST_STAGE = "first_stage";
const DIVORCED = "divorced";

const NO_KEYS = ["D5", "D6", "D9", "D10", "F22"];
const NO_KEYS_GUIDES = ["DR3", "DR7", "NG16", "NG17", "NG18", "NG19", "NG20A"];
const APPLICATION_KEYS = ["D10", "F22"];
const APPLICATION_KEYS_GUIDES = ["NG19", "NG20A"];
const FIRST_STAGE_KEYS = ["F22"];
const FIRST_STAGE_KEYS_GUIDES = ["NG20A"];

const answers = [
  {
    title: "What’s the difference between a marriage and a civil partnership?",
    content: `<p>The process for dissolving a civil partnership and the financial separation is the same as for divorce.  The main difference is the terminology.  We are working on updating this site to use the civil partnership terminology but in the meantime, you can assume that when we say divorce we also mean civil partnership dissolution. 
</p>`,
  },
  {
    title: "What’s a Conditional Order?",
    content:
      "<p>A Conditional Order is a formal document from the court that confirms that everything you’ve filed at the court about your divorce (or dissolution) so far has been reviewed and the court sees no reason why you can’t have a divorce.  It’s a stepping stone towards legally ending your marriage (or civil partnership).</p>",
  },
  {
    title: "How do I know whether I’m divorced?",
    content:
      "<p>Divorce is the process of legally ending a marriage.  A legal document called a ‘Decree Absolute’ or since April 2022 a ‘Final Order’ will confirm you are divorced and you will need this document as legal proof of your divorce.</p><p>Remember, a divorce in itself does not mean that the financial side of things has been sorted out.  You can find out more about this in your Finances Space.</p>",
  },
  {
    title: "How do I know if my civil partnership has been dissolved?",
    content:
      "<p>Dissolution is the process of legally ending a civil partnership.  A legal document called a ‘Final Order’ will confirm your partnership has been dissolved and you will need this document as legal proof of your dissolution.</p><p>Remember, dissolution in itself does not mean that the financial side of things has been sorted out.  You can find out more about this in your Finances Space.</p>",
  },
];

const validationForm5 = yup.object({
  started_process: yup.string().nullable().required("Please select an option."),
});

export const FormGB5 = ({
  handleBack,
  setData,
  handleNextStep,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );

  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = ev.target;

      switch (value) {
        case NO:
          dispatch(addId(NO_KEYS));
          dispatch(addResourceId(NO_KEYS_GUIDES));
          break;
        case APPLICATION:
          dispatch(removeId(NO_KEYS));
          dispatch(removeResourceId(NO_KEYS_GUIDES));
          dispatch(addId(APPLICATION_KEYS));
          dispatch(addResourceId(APPLICATION_KEYS_GUIDES));

          break;
        case FIRST_STAGE:
          dispatch(removeId(NO_KEYS));
          dispatch(removeResourceId(NO_KEYS_GUIDES));
          dispatch(addId(FIRST_STAGE_KEYS));
          dispatch(addResourceId(FIRST_STAGE_KEYS_GUIDES));

          break;
        case DIVORCED:
          dispatch(removeId(NO_KEYS));
          dispatch(removeResourceId(NO_KEYS_GUIDES));
          break;
      }

      formik.handleChange(ev);
      setData(name, value);
    },
    [dispatch, generalOnboarding]
  );

  const formik = useFormik({
    initialValues: generalOnboarding,
    validationSchema: validationForm5,
    onSubmit: (values) => {
      if (values.started_process !== NO) {
        dispatch(setBoardingStep(activeStep + 1));
      } else {
        handleNextStep();
      }
    },
  });

  return (
    <form id="form-5" onSubmit={formik.handleSubmit} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3">
          You’ve told us that your marriage or civil partnership is ending or
          has ended.
        </Htag>
        <Htag tag="h3">
          Have you started the legal process of divorce (or civil partnership
          dissolution) yet?
        </Htag>

        <div className={s.form_oneline}>
          <div className={s.formField}>
            <RadioGroup row name="started_process" className={s.radioboxes}>
              <FormControlLabel
                control={<Radio color="default" onChange={updateInterview} />}
                label="No, I haven’t taken any steps yet and I don’t think my ex has either."
                value={NO}
                checked={formik.values.started_process == NO}
              />
              <FormControlLabel
                value={APPLICATION}
                control={<Radio color="default" onChange={updateInterview} />}
                label="Yes, a divorce application has been made."
                checked={formik.values.started_process == APPLICATION}
              />
              <FormControlLabel
                label="Yes, the first stage order in our divorce has been made - called a Conditional Order of Divorce."
                value={FIRST_STAGE}
                control={<Radio color="default" onChange={updateInterview} />}
                checked={formik.values.started_process == FIRST_STAGE}
              />
              <FormControlLabel
                label="Yes, we’re already divorced."
                value={DIVORCED}
                control={<Radio color="default" onChange={updateInterview} />}
                checked={formik.values.started_process == DIVORCED}
              />
            </RadioGroup>
            {formik.touched.started_process &&
              formik.errors.started_process && (
                <ErrorField error={formik.errors.started_process} />
              )}
          </div>
        </div>
      </>

      <Accordion className={s.desc} accordionData={answers} />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
