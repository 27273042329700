import s from "./GeneralOnboarding.module.css";

import { Button, Htag, Icon3 } from "components";

import { QuestionnaireProps } from "pages/Dashboard/Parenting/CQuestionnaire/Questionnaire.props";
import { useAppSelector } from "utils/hooks";

export const FirstScreen = ({ handleNextStep }: QuestionnaireProps) => {
  const userName = useAppSelector((state) => state.userState.user.name);

  return (
    <div className={s.firstSlide}>
      <Icon3 className={s.firstSlideIcon} />
      <Htag tag="h3">
        Welcome {userName}, you have subscribed to SeparateSpace
      </Htag>
      <p>
        We'll guide you through 13 questions to personalise your dashboard to
        your circumstances. Think of these questions as a conversation with a
        divorce expert.
      </p>
      <p>
        Each question is accompanied by an explanation, helping you understand
        why it's relevant. By taking your time with these questions, you'll gain
        valuable insights as you answer them.
      </p>

      <div className={s.groupButton}>
        <Button onClick={() => handleNextStep()} apearance="secondary">
          I understand
        </Button>
      </div>
    </div>
  );
};
