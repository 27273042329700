import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CREATE_POST,
  DELETE_POST,
  GET_ALL_POSTS,
  GET_RECENT_POSTS,
  GET_SINGLE_POST,
  UPDATE_POST,
} from "../config";
import { IInitState } from "../root-interface";
import { IGetPostsData, Post } from "./blog.interfaces";
import { getRequestFabric, API_TYPE } from "../thunk-helper";

export const add_post = createAsyncThunk<
  { success: string },
  FormData,
  { getState: IInitState; rejectValue: string }
>(
  "@@blog/adding",
  async function (data, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.POST,
      getState,
      CREATE_POST(),
      rejectWithValue,
      dispatch,
      data
    );
  }
);

export const get_all_posts = createAsyncThunk<
  IGetPostsData,
  { page: number; limit: number },
  { getState: IInitState; rejectValue: string }
>("@@blog/getting", async function (data, { rejectWithValue }) {
  const { page, limit } = data;

  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };
  const response = await fetch(GET_ALL_POSTS(page, limit), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as IGetPostsData;
  }
});

export const get_recent_posts = createAsyncThunk<
  Post[],
  number,
  { getState: IInitState; rejectValue: string }
>("@@blog/gettingRecentPosts", async function (post_id, { rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };
  const response = await fetch(GET_RECENT_POSTS(post_id), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as Post[];
  }
});
export const get_all_user_posts = createAsyncThunk<
  IGetPostsData,
  { page: number; limit: number },
  { getState: IInitState; rejectValue: string }
>("@@blog/gettingUP", async function (data, { rejectWithValue }) {
  const { page, limit } = data;

  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };
  const response = await fetch(GET_ALL_POSTS(page, limit), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        return rejectWithValue(result.message);
      });
  } else {
    return (await response.json()) as IGetPostsData;
  }
});

export const deletePost = createAsyncThunk<
  { page_id: number; succes: string },
  string,
  { getState: IInitState; rejectValue: string }
>(
  "@@blog/deletePost",
  async function (post_id, { getState, rejectWithValue, dispatch }) {
    return await getRequestFabric(
      API_TYPE.DELETE,
      getState,
      DELETE_POST(post_id),
      rejectWithValue,
      dispatch
    );
  }
);

export const get_single_post = createAsyncThunk<
  Post,
  string,
  { getState: IInitState; rejectValue: string }
>("@@blog/singlePost", async function (id, { rejectWithValue }) {
  const header = {
    method: "GET",
    headers: {
      Accept: "application/json",
      responseType: "json",
    },
  };
  const response = await fetch(GET_SINGLE_POST(id), header);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      localStorage.removeItem("userToken");
    }
    return response
      .text()
      .then((result) => JSON.parse(result))
      .then((result) => {
        if (result) return rejectWithValue(result.error);
        else return rejectWithValue("Server error");
      });
  } else {
    return (await response.json()) as Post;
  }
});

export const updatePost = createAsyncThunk<
  { success: string; blog: Post },
  {
    post_id: string;
    data: {
      title: string;
      content: string;
      reading_time: string;
      tags: string[];
      image: Blob | null | string;
      categories: string[];
      seo: {
        title: string;
        keywords: string;
        description: string;
      };
    };
  },
  { getState: IInitState; rejectValue: string }
>(
  "@@blog/updatePost",
  async function ({ post_id, data }, { getState, rejectWithValue, dispatch }) {

    const formData = new FormData();
    const fields = {
      title: data.title,
      content: data.content,
      reading_time: data.reading_time,
      "seo[title]": data.seo.title,
      "seo[description]": data.seo.description,
      "seo[keywords]": data.seo.keywords,
      image: data.image || "",
    };

    Object.entries(fields).forEach(([key, value]) =>
      formData.append(key, value)
    );

    (data.tags.length ? data.tags : [""]).forEach((tag) =>
      formData.append("tags[]", tag)
    );
    (data.categories.length ? data.categories : [""]).forEach((category) =>
      formData.append("categories[]", category)
    );

    return await getRequestFabric(
      API_TYPE.POST_DATA,
      getState,
      UPDATE_POST(post_id),
      rejectWithValue,
      dispatch,
      formData
    );
  }
);
