import { PageType } from 'store/system_resources/system_resource.interface';
import { useAppSelector } from 'utils/hooks';

const useCategories = (pageType: PageType) => {
  const categories = useAppSelector((state) => {
    switch (pageType) {
      case PageType.parenting:
        return state.allSpaces.categories;

      case PageType.wellbeing:
        return state.wellbeing.categories;

      default:
        return state.allSpaces.categories;
    }
  });

  return categories;
};

export default useCategories;
