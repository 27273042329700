import { useCallback } from "react";
import { Accordion, Button, Htag } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { QuestionnaireProps } from "pages/Dashboard/Parenting/CQuestionnaire/Questionnaire.props";
import {
  addResourceId,
  removeResourceId,
} from "store/system_resources/system_resource-reducer";
import s from "./GeneralOnboarding.module.css";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const desc = `<p>We have lots of resources about how to value assets for a divorce and how they might be treated in a financial settlement.</p><p>Everyone’s financial circumstances are different and we’re aiming to provide you with the information that is relevant to your circumstances. That’s why we ask you to tell us which categories of assets and liabilities apply to you and your ex.</p><p>You will be able to search all of our resources so don’t worry if you find out later that one of these categories is relevant to you.  (For example, you may find out later that your ex has a life insurance policy).  For now, just tick all of the boxes which you think apply.</p>`;

export const FormGB9 = ({
  handleBack,
  handleNextStep,
  setData,
}: QuestionnaireProps): JSX.Element => {
  const { generalOnboarding, activeStep } = useAppSelector(
    (state) => state.generalOnBoardingState
  );
  const dispatch = useAppDispatch();

  const updateInterview = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, checked } = ev.target;

      switch (name) {
        case "shares":
          if (checked) {
            dispatch(addResourceId(["FR10"]));
          } else {
            dispatch(removeResourceId(["FR10"]));
          }
          break;
        case "cryptocurrency":
          if (checked) {
            dispatch(addResourceId(["FR11"]));
          } else {
            dispatch(removeResourceId(["FR11"]));
          }
          break;
        case "business":
          if (checked) {
            dispatch(addResourceId(["FR12"]));
          } else {
            dispatch(removeResourceId(["FR12"]));
          }
          break;
        case "car":
          if (checked) {
            dispatch(addResourceId(["FR14"]));
          } else {
            dispatch(removeResourceId(["FR14"]));
          }
          break;
        case "life_insurance":
          if (checked) {
            dispatch(addResourceId(["FR15"]));
          } else {
            dispatch(removeResourceId(["FR15"]));
          }
          break;
        case "owed_money":
        case "money_owe":
          if (checked) {
            dispatch(addResourceId(["FR16"]));
          } else {
            dispatch(removeResourceId(["FR16"]));
          }
          break;
        default:
          break;
      }
      setData("debts", [
        ...generalOnboarding.debts.slice(0, index),
        {
          ...generalOnboarding.debts[index],
          value: ev.target.checked,
        },
        ...generalOnboarding.debts.slice(index + 1),
      ]);
    },
    [dispatch, generalOnboarding]
  );

  return (
    <form id="form-9" onSubmit={() => handleNextStep()} className={s.form}>
      <span className={s.stepMark}>{`${activeStep} `}</span>

      <>
        <Htag tag="h3">
          Please indicate if you or your ex have any of the following assets or
          debts.
        </Htag>
        <Htag tag="h3">
          You will need to be thinking about the overall financial picture so it
          doesn't matter who's name the assets or debts are in - please tick all
          of the categories that apply to both or either of you.
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <FormGroup>
              {generalOnboarding?.debts.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={
                    <Checkbox
                      color="default"
                      onChange={(event) => updateInterview(event, index)}
                    />
                  }
                  name={item.name}
                  label={item.label}
                  checked={item.value}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </>

      <Accordion
        className={s.desc}
        accordionData={[
          {
            title: `Why are we asking this?`,
            content: desc,
          },
        ]}
      />

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button type="submit" apearance="secondary">
          Next
        </Button>
      </div>
    </form>
  );
};
