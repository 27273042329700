import { useEffect, useState } from "react";
import s from "./InputImage.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { InputFileProps } from "./InputImage.props";

export const InputImageComponent = ({
  handleFiles,
  file,
  name,
  handleOnBlur,
  id,
  ...props
}: InputFileProps): JSX.Element => {
  const [imageUrl, setImageUrl] = useState<string>("");

  const handleselectedFile = (event: any) => {
    const imgUrl = URL.createObjectURL(event.target.files[0]);
    console.log(name);
    handleFiles(name, event.target.files[0]);
    setImageUrl(imgUrl);
  };

  const removeFile = () => {
    console.log(name);
 handleFiles(name, "");
    // handleOnBlur(name, undefined);
  };

  useEffect(() => {
    if (typeof file === "string") {
      setImageUrl(file);
    } else if (file instanceof File) {
      const objectUrl = URL.createObjectURL(file);
      setImageUrl(objectUrl);
      // Clean up the object URL when the component unmounts or the file changes
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else {
      setImageUrl("");
    }
  }, [file]);

  return (
    <div {...props}>
      <input
        type="file"
        name={name}
        accept="image/*"
        id={id}
        onChange={handleselectedFile}
        style={{ display: "none" }}
      />
      <label className={s.uploadButton} htmlFor={id}>
        <div className={s.uploadFileBtn}>Upload file</div>
      </label>
      {file ? (
        <div className={s.attachImage}>
          <img src={imageUrl} alt={name} />
          <CloseIcon name={name} id={name} onClick={removeFile} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
