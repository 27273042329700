import { Htag, Notification } from "components";

import { useAppDispatch, useAppSelector } from "utils/hooks";
import s from "./Parenting.module.css";

import { loadParenting } from "store/parenting/parenting-thunk";
import { useEffect, useMemo, useState } from "react";
import { loadTickets } from "store/tickets/tickets-thunk";
import { BoardsComponent } from "../../../components/Boards/Boards.component";
import { TemplatesComponent } from "../../../components/Templates/Templates.component";
import { ResourcesListComponent } from "../../../components/SResources/ResourcesList.component";
import _ from "lodash";
import { FullModalStyled } from "components/FullWidthModal/FullModal.component";
import { CQuestionnaire } from "./CQuestionnaire/CQuestionnaire.component";
import { setToInit } from "store/questionnaires/questionnaires.reducer";

import { IMemory } from "store/memory/memory-thunk";
import { CaseStudyList } from "../CaseStudies/CaseStudyList.component";
import { getVariableForWidth } from "utils/utils";

import { setChildrenResources } from "store/parenting/spaces-reducer";
import { getAllCategories } from "store/categories/categories.thunk";
import cn from "classnames";
import useGroupBy from "utils/groupBy.hook";
import { Guide } from "store/system_resources/system_resource.interface";
import { Category } from "store/categories/categories.interface";

export const ParentingPage = () => {
  const dispatch = useAppDispatch();
  const [openQuestionnaire, setOpenQuestionnaire] = useState(false);
  const categories = useAppSelector((state) => state.allSpaces.categories);

  const [index, setIndex] = useState(0);

  const milestoneMemory = useAppSelector((state) =>
    state.userState.user.memory.filter(
      (item: IMemory) => item.name == `Notification_Children_2`
    )
  );
  const CQuestionnaireEntityLength = useAppSelector((state) => {
    if (!state.userState.user.questionnaires) return true;
    return _.isEmpty(state.userState.user.questionnaires.childrenQuestionnary);
  });

  const living_property = useAppSelector(
    (state) =>
      state.userState.user.questionnaires.generalQuestionnary?.living_property
  );

  const account_status = useAppSelector(
    (state) => state.accountState.subscription.status
  );

  const space = useAppSelector((state) => state.allSpaces);
  const activeStep = useAppSelector(
    (state) => state.questionnairesState.activeStep
  );

  const [mergedGuides, setMergeGuides] = useState<Category[]>([]);

  const groupedGuides = useGroupBy(space.space_resources, "category_id");

  const shouldOpenQuestionnaire = useMemo(() => {
    if (account_status !== "active" || !living_property) return false;

    return CQuestionnaireEntityLength;
  }, [account_status, 2]);

  const handleCloseQuestionnaire = () => {
    setOpenQuestionnaire(false);
    dispatch(setToInit());
  };

  useEffect(() => {
    if (!categories.length) return;
    const arr: Category[] = Object.keys(groupedGuides)
      .filter((item) => item !== "null" && item !== null)
      .map((item) => {
        const ctData = categories.filter((ct) => ct.id == Number(item))[0];

        return {
          id: Number(item),
          title: ctData.title,
          guides: groupedGuides[item],
          type: ctData.type,
        };
      });
    if (arr && arr.length) setMergeGuides(arr);
    console.log(arr);
  }, [space, categories]);

  useEffect(() => {
    setOpenQuestionnaire(shouldOpenQuestionnaire);
  }, [shouldOpenQuestionnaire]);

  useEffect(() => {
    dispatch(loadParenting())
      .unwrap()
      .then((resp: any) => {
        setChildrenResources(resp);
      });
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    dispatch(loadTickets(2));
  }, []);

  const caseStudies = useAppSelector(
    (state) => state.caseStudiesState.caseStudiesArray
  );

  useEffect(() => {
    const handleResize = () => {
      const w = getVariableForWidth(window.innerWidth);

      if (w == "small") {
        setIndex(space.space_resources.length);
      } else {
        setIndex(4);
      }
    };

    window.addEventListener("resize", handleResize);
    if (!space.space_resources.length) {
      window.addEventListener("resize", handleResize);
    } else {
      handleResize();
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [space]);

  return (
    <>
      <Htag tag="h1">Focus on parenting</Htag>
      {milestoneMemory[0]?.value !== "true" && (
        <Notification name={`Children_2`}>
          This is your space for supporting your children, sorting out your
          childcare and parenting arrangements. All the actions and guides have
          been designed by our experts to minimise the impact of divorce on your
          family. We have experts in family law, finances and child psychology.
        </Notification>
      )}
      <BoardsComponent />
      {/*   <section className={s.guidesWrap}>
        <div className={s.resources_header}>
          <Htag tag="h2" className="mb10">
            Case studies
          </Htag>
          <p>
            Each case study highlights multiple options for how these families
            approached their separation.
          </p>
        </div>

        <CaseStudyList data={caseStudies} />
      </section> */}
      {mergedGuides.length > 0 &&
        mergedGuides.map((ct, index) => (
          <div key={index} className={cn(s.guidesWrap, "mb32")}>
            {ct.guides && ct.guides.length > 0 && (
              <>
                <Htag tag="h2">{ct.title}</Htag>{" "}
                <ResourcesListComponent
                  show_in_row={4}
                  resources={ct.guides}
                  showTgas={false}
                />
              </>
            )}
          </div>
        ))}

      <section className={s.guidesWrap}>
        <TemplatesComponent />
      </section>

      <FullModalStyled
        color={activeStep == 0 ? "dark" : "light"}
        open={openQuestionnaire}
        close={() => setOpenQuestionnaire(false)}
      >
        <CQuestionnaire close={handleCloseQuestionnaire} />
      </FullModalStyled>
    </>
  );
};
