import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetToDefault } from "../login/login-reducer";
import {
  get_single_sresource,
  get_system_resources,
  updateSystemResource,
} from "./system_resource-thunk";
import { Guide, ISystemResources } from "./system_resource.interface";

const initialState: ISystemResources = {
  resources: [],
  resources_db: [],
  error: "",
  loading: false,
};

const specialResourcesState = createSlice({
  name: "@@specialResources",
  initialState,
  reducers: {
    addResourceId: (
      state: ISystemResources,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.resources.push(action.payload);
      } else {
        const toRemove = new Set(action.payload);
        state.resources = state.resources.filter((x) => !toRemove.has(x)) || [];
        state.resources = state.resources.concat(action.payload);
      }
    },
    removeResourceId: (
      state: ISystemResources,
      action: PayloadAction<string | string[]>
    ) => {
      if (typeof action.payload == "string") {
        state.resources = state.resources.filter(
          (item) => item !== action.payload
        );
      } else {
        const toRemove = new Set(action.payload);

        state.resources = state.resources.filter((x) => !toRemove.has(x)) || [];
      }
    },
    resetResourcesIds: (state) => {
      state.resources = [];
    },
    saveBookmark: (state: ISystemResources, action) => {
      state.resources_db = state.resources_db.map((item) => {
        if (item.id == action.payload) {
          item.bookmark = true;
        }
        return item;
      });
    },
    removeBookmark: (state: ISystemResources, action) => {
      state.resources_db = state.resources_db.map((item) => {
        if (item.id == action.payload.id) {
          item.bookmark = false;
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetToDefault, () => {
        return initialState;
      })
      .addCase(
        get_system_resources.fulfilled,
        (state: ISystemResources, action: PayloadAction<Guide[]>) => {
          state.resources_db = action.payload;
        }
      )
      .addCase(
        get_single_sresource.fulfilled,
        (state: ISystemResources, action: PayloadAction<Guide>) => {
          state.resources_db = state.resources_db.filter(
            (page) => page.id !== action.payload.id
          );

          state.resources_db.push(action.payload);
        }
      )
      .addCase(
        updateSystemResource.fulfilled,
        (state: ISystemResources, action) => {
          state.resources_db = state.resources_db.map((x) => {
            if (x.id == action.payload.resource.id) {
              return action.payload.resource;
            }
            return x;
          });
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;

          if (action.payload) {
            state.error = action.payload;
          } else if (action.error.message) {
            state.error = action.error.message;
          } else {
            state.error = "Some server error";
          }
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = "";
          state.loading = false;
        }
      );
  },
});

export const {
  addResourceId,
  removeResourceId,
  removeBookmark,
  saveBookmark,
  resetResourcesIds,
} = specialResourcesState.actions;

export default specialResourcesState.reducer;
