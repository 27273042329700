import axios from "axios";
import { useEffect } from "react";

import { Htag } from "components";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { setBookmarks } from "store/bookmarks/bookmarks-reducer";
import { GET_ALL_BOOKMARKS } from "store/config";

import s from "./SavedArticles.module.css";
import { ResourcesListComponent } from "../../../components/SResources/ResourcesList.component";
import { CaseStudyList } from "../CaseStudies/CaseStudyList.component";

export const SavedArticles = (): JSX.Element => {
  const userToken = useAppSelector((state) => state.userState.token);
  const { sresource, cstudies, wresources } = useAppSelector(
    (state) => state.bookmarksState
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    axios
      .get(GET_ALL_BOOKMARKS(), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken,
        },
        responseType: "json",
      })
      .then((res) => {
        dispatch(setBookmarks(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <header className={s.saveBookmarksHeader}>
        <Htag tag="h1">Saved resources</Htag>
        <p>
          Here are the resources that you have saved. If you’d like to remove
          one from this saved resources area, click on it and press ‘save
          resource’.
        </p>
      </header>

      {sresource.length > 0 && (
        <div className={s.bookmarks_block}>
          <header>
            <Htag tag="h2">Saved resources</Htag>
          </header>

          <ResourcesListComponent
            show_in_row={4}
            showTgas={false}
            resources={sresource}
          />
        </div>
      )}
      {wresources.length > 0 && (
        <div className={s.bookmarks_block}>
          <header>
            <Htag tag="h2">Wellbeing Guides</Htag>
          </header>
          <ResourcesListComponent
            show_in_row={4}
            showTgas={false}
            resources={wresources}
          />
        </div>
      )}
      {cstudies.length > 0 && (
        <div className={s.bookmarks_block}>
          <header>
            <Htag tag="h2">Saved Case Studies</Htag>
          
          </header>
          <CaseStudyList data={cstudies} />
        </div>
      )}
    </>
  );
};
