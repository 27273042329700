import {
  Alert,
  FormControl,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";

import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import s from "./AddWGuide.module.css";
import { NavLink, useParams } from "react-router-dom";
import {
  Button,
  ErrorField,
  Htag,
  InputImageComponent,
} from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";

import { EditorComponent } from "../../../../components/Editor/Editor.component";
import {
  FileProps,
  Guide,
  IAttach,
} from "../../../../store/system_resources/system_resource.interface";
import { InputFileComponent } from "../../../../components/InputFile/InputFile.component";
import { useFormik } from "formik";

import { validationCreateSchema } from "pages/AdminDashboard/validation";

import {
  updateWGuide,
} from "store/wellbeing/wellbeing-thunk";
import { commonSelectProps } from "./EditSources.component";
import { milestones } from "store/milestones/milestones-interface";
import { loadSingleWSGuide } from "store/wellbeing/wsystem_resources.thunk";
import { getAllCategories } from 'store/categories/categories.thunk';
import { Resource } from 'models/Guide.model';

export const EditWSSourcePage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [currentAttachments, setAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);
  const [attachmentsOld, setOldAttachments] = useState<
    Partial<FileProps | IAttach>[]
  >([]);
  const guide: Guide = useAppSelector((state) => {
    return state.wellbeing.system_resources.filter(
      (guide: Guide) => guide.id == id
    );
  })[0];

  const categories = useAppSelector((state) => state.wellbeing.categories);

  const formik = useFormik({
    initialValues: new Resource(),
    validationSchema: validationCreateSchema,
    onSubmit: (values) => {
      const updatedValue = formik.initialValues.formData(values);
      dispatch(updateWGuide({ id: Number(id), data: updatedValue })).then(
        () => {
          setOpen(true);
        }
      );
    },
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!id || !guide) return;
    if (!guide.content) {
      dispatch(loadSingleWSGuide(Number(id)));
    }
  }, []);

  useEffect(() => {
    setOldAttachments(guide.attachments);
    const fields = {
      content: guide.content || "",
      milestone_id: guide.milestone_id || null,
      title: guide.title || "",
      unique_number: guide.unique_number,
      reading_time: guide.reading_time || "",
      category_id: guide.category_id || null,
      image: guide.image,
    };
    Object.entries(fields).forEach(([key, value]) => {
      formik.setFieldValue(key, value);
    });
  }, [guide]);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  const handleAtachments = (attachments: FileProps | IAttach) => {
    setAttachments((old) => [...old, attachments]);

    formik.setFieldValue("attachments", [...currentAttachments, attachments]);
  };

  return (
    <div className={s.formWrap}>
      <Htag tag="h3">Edit source: "{formik.values.title}"</Htag>
      <form onSubmit={formik.handleSubmit}>
        <div className={s.formField}>
          <Htag tag="h4">Title: </Htag>
          <FormControl fullWidth className={s.title}>
            <input
              id="title"
              name="title"
              onBlur={formik.handleBlur}
              value={formik.values.title}
              type="text"
              onChange={formik.handleChange}
              placeholder="Title"
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorField error={formik.errors.title} />
            )}
          </FormControl>
        </div>
        <div className={s.formField}>
          <Htag tag="h4">Reading time: </Htag>
          <FormControl className={s.reading_time}>
            <input
              id="reading_time"
              name="reading_time"
              value={formik.values.reading_time}
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Reading time"
            />
            {formik.touched.reading_time && formik.errors.reading_time && (
              <ErrorField error={formik.errors.reading_time} />
            )}
          </FormControl>
        </div>
        {/*      <FormControlLabel
          label="Need a journal"
          className={s.need_journal}
          control={
            <Checkbox
              name="need_journal"
              id="need_journal"
              checked={formik.values.need_journal}
              onChange={formik.handleChange}
            />
          }
        /> */}
        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload preview image
          </Htag>
          <InputImageComponent
            handleFiles={formik.setFieldValue}
            handleOnBlur={formik.setFieldValue}
            file={formik.values.image}
            id="image"
            name="image"
          />
        </div>

        <div className={s.formField}>
          <Htag tag="h4" className={s.headline}>
            Upload attachments
          </Htag>
          <InputFileComponent
            files={attachmentsOld}
            handleFiles={handleAtachments}
            id="editResource"
          />
        </div>
        <div className={s.wyswyg_editorWrap}>
          <EditorComponent
            value={formik.values.content}
            handleChange={(e) => formik.setFieldValue("content", e)}
          />
        </div>

        <div className={s.selectsWrap}>
          <div className={s.select}>
            <Htag tag="h4" className="mb10">
              Connect to divorce step:
            </Htag>
            {
              <TextField
                {...commonSelectProps}
                name="milestone_id"
                label={!formik.values.milestone_id  ? "Task is…" : ""}
                defaultValue={
                  formik.values.milestone_id === null
                    ? ""
                    : formik.values.milestone_id
                }
                value={
                  formik.values.milestone_id === null
                    ? ""
                    : formik.values.milestone_id
                }
                onChange={formik.handleChange}
              >
                <MenuItem value={JSON.stringify(null)}>
                  <em>None</em>
                </MenuItem>
                {milestones.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            }
          </div>
          <div className={s.select}>
            <Htag tag="h4" className="mb10">
              Choose category:
            </Htag>
            {
              <TextField
                {...commonSelectProps}
                name="category_id"
                label={!formik.values.category_id ? "Category is…" : ""}
                defaultValue={
                  formik.values.category_id === null
                    ? ""
                    : formik.values.category_id
                }
                value={
                  formik.values.category_id === null
                    ? ""
                    : formik.values.category_id
                }
                onChange={formik.handleChange}
              >
                <MenuItem value={JSON.stringify(null)}>
                  <em>None</em>
                </MenuItem>
                {categories.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            }
          </div>
        </div>
        <p className={s.previewLink}>
          <NavLink
            target="_blank"
            to={`/admin-dashboard/wellbeing/guides/edit-page/${id}/preview`}
          >
            Preview
          </NavLink>
        </p>
        <Button apearance="secondary">Save</Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {formik.values.title} is updated!
        </Alert>
      </Snackbar>
    </div>
  );
};
