import {
  BookYourTime,
  CaseStudyUser,
  CoachList,
  Dashboard,
  ParentingPage,
  SavedArticles,
  SearchResults,
  User,
  WellBeingIndex,
  WellbeingArticleComponent,
} from "pages";
import { RouteType } from "./config";
import DashboardPageLayout from "layout/DashboardPageLayout.component";

import PathWayComponent from "pages/Dashboard/PathWay/PathWay.component";
import { AtlaAIPageComponent } from "pages/Dashboard/AtlaAI/AtlaAI.component";
import { GuideArticle } from "pages/Dashboard/GuideArticle/GuideArticle.component";

import { SingleTicket } from "components/SingleTicket/SingleTicket.component";
import { DivorceStep } from "pages/Dashboard/DivorceStep/DivorceStep.component";
import { EResourceArticleComponent } from "components/ExtraArticles/ResourceArticle.component";
import { WellbeingPage } from "pages/Dashboard/Wellbeing/Wellbeing.component";

export const userSidebarRoutes: RouteType[] = [
  {
    path: "/dashboard",
    element: <DashboardPageLayout />,
    state: "home",
    menuProps: {
      displayText: "Home",
    },
  },
  {
    path: "/profile",
    element: <User />,
    state: "profile",
    menuProps: {
      displayText: "Profile",
    },
  },

  {
    path: "/dashboard",
    element: <DashboardPageLayout />,
    state: "dashboard",
    menuProps: {
      displayText: "Dashboard",
    },
    child: [
      {
        index: true,
        element: <Dashboard />,
        path: "/dashboard/",
        state: "dashboard.index",
      },

      {
        path: "/dashboard/milestone/:id",
        element: <DivorceStep />,
        state: `dashboard.milestone`,
      },
      {
        path: "/dashboard/:id/:ticketId",
        element: <SingleTicket />,
        state: `dashboard.ticket`,
      },
      {
        element: <GuideArticle />,
        path: "/dashboard/guides/:guideId",
        state: `dashboard.guides`,
      },
      {
        path: `/dashboard/parenting`,
        element: <ParentingPage />,
        state: `dashboard.children`,

        child: [
          {
            index: true,
            path: "/dashboard/parenting",
            element: <ParentingPage />,
            state: `dashboard.children.index`,
          },
        ],
      },
      {
        path: "/dashboard/my-wellbeing",
        element: <WellbeingPage />,
        state: "dashboard.myWellbeing",
        child: [
          {
            index: true,
            path: "/dashboard/my-wellbeing",
            element: <WellBeingIndex />,
            state: "dashboard.myWellbeing.index",
          },
          {
            path: "/dashboard/my-wellbeing/guide/:id",
            element: <WellbeingArticleComponent />,
            state: "dashboard.myWellbeing.guide",
          },
        ],
      },
      {
        path: "/dashboard/bookmarks",
        element: <SavedArticles />,
        state: "dashboard.savedArticles",

        menuProps: {
          displayText: "Saved resources",
        },
      },
      {
        path: "/dashboard/pathway",
        element: <PathWayComponent />,
        state: "dashboard.pathway",
      },
      {
        path: "/dashboard/articles/:guideId",
        element: <EResourceArticleComponent />,
        state: "dashboard",
      },
    /*   {
        path: "/dashboard/bookmarks/:source/:id",
        element: <SavedSingleArticle />,
        state: "dashboard.savedArticles.singleArticle",
      }, */
      {
        element: <SearchResults />,
        path: "/dashboard/search-results/",
        state: "searchResults",
      },
      {
        element: <CaseStudyUser />,
        path: "/dashboard/case_studies/:id/",
        state: "dashboard.finance",
      },
      {
        element: <CoachList />,
        path: "/dashboard/divorce-expert/",
        state: "dashboard.divorce-expert",
      },
      {
        element: <BookYourTime />,
        path: "/dashboard/divorce-expert/:id/booking",
        state: "dashboard.bookingCoach",
      },
      {
        element: <AtlaAIPageComponent />,
        path: "/dashboard/atlaAI",
        state: "dashboard.atlaAI",
      },
    ],
  },
];
