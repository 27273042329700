import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import s from "../SpecialSources.module.css";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { Button, Htag } from "components";
import { IExtraResource } from "store/extra_resources/extra_resource.interface";
import {
  deleteEResource,
  get_all_extra_resources,
} from "store/extra_resources/extra_resource-thunk";
interface deleteObj {
  id: number;
  delFunc: (id: number) => void;
}
export const ListExtraResources = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [checkedItem, setCheckedItem] = useState<deleteObj | null>(null);
  const extra_resources: IExtraResource[] = useAppSelector(
    (state) => state.extraResources.resources
  );
  const handleClose = () => {
    setOpenDeleteDialog(false);
    setCheckedItem(null);
  };
  const copy = async (id: string) => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/dashboard/articles/${id}`
    );
  };
  useEffect(() => {
    dispatch(get_all_extra_resources());
  }, [dispatch]);

  const confirmDelete = () => {
    checkedItem?.delFunc(checkedItem.id);
    setCheckedItem(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteResource = (id: number) => {
    if (!id) return;
    dispatch(deleteEResource(id));
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: s.headerClassName,
      width: 70,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "unique_number",
      headerName: "Unique number",
      headerClassName: s.headerClassName,
      width: 120,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerClassName: s.headerClassName,
      headerName: "Title",
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: `Action`,
      headerClassName: s.headerClassName,
      sortable: false,
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className={s.actions}>
            <NavLink to={`${pathname}/edit-guide/${params.row.id}`}>
              <IconButton aria-label="edit" color="info">
                <Edit />
              </IconButton>
            </NavLink>

            <ContentCopyIcon
              className={s.externalLink}
              onClick={() => copy(params.id.toString())}
            />
            <IconButton
              aria-label="delete"
              color="error"
              onClick={(e) => {
                e.stopPropagation();

                setCheckedItem({
                  id: Number(params.row.id),
                  delFunc: handleDeleteResource,
                });
                setOpenDeleteDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Htag tag="h3">List of extra resources</Htag>
      <Box sx={{ height: 'auto', width: "100%" }}>
        <DataGrid
          rows={extra_resources}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          className={s.tableWrap}
          pageSizeOptions={[10, 30, 50, 70, 100]}
        />
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={s.deleteConfirmDialog}
      >
        <DialogContent className={s.deleteConfirmText}>
          Please, confirm deleting
        </DialogContent>
        <DialogActions className={s.deleteConfirmBtn}>
          <Button apearance="secondaryDark" onClick={handleClose}>
            NO
          </Button>
          <Button apearance="secondaryDark" onClick={confirmDelete}>
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
