import s from "./Questionnaire.module.css";

import { Button, Htag } from "components";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import { setCQuestionnaire } from "store/questionnaires/questionnaires.reducer";

import { resetTicketsIds } from "store/system_tickets/system_ticket-reducer";
import { add_system_tickets } from "store/system_tickets/system-ticket-thunk";
import { saveCOnboarding } from "store/questionnaires/questionnaires.thunk";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { QuestionnaireProps } from "./Questionnaire.props";
import { loadTickets } from "store/tickets/tickets-thunk";
import { useEffect } from "react";
import {
  addResourceId,
  removeResourceId,
  resetResourcesIds,
} from "store/system_resources/system_resource-reducer";
import { add_system_resources } from "store/system_resources/system_resource-thunk";
import {
  addWResourceId,
  removeWResourceId,
} from "store/wellbeing/wellbeing-reducer";
import { add_wsystem_resources } from "store/wellbeing/wsystem_resources.thunk";
import cn from "classnames";
import { add_case_studies } from "store/caseStudy/caseStudy_thunk";
import { resetCSIds } from "store/caseStudy/caseStudy.reducer";
import { sortArray } from "utils/utils";
import { loadParenting } from "store/parenting/parenting-thunk";

const particular_concerns = [
  {
    title: "Positive co-parenting.",
    value: "pos_coparenting",
  },
  {
    title: "Behavioural issues that can be common when parents separate.",
    value: "behavioural",
  },
  {
    title: "My child’s reluctance to spend time with me or with my ex.",
    value: "child_reluctance",
  },
  {
    title: "Supporting children when they meet a new partner.",
    value: "supporting_children",
  },
  {
    title: "Making sure the children feel positive about both of us.",
    value: "positive_feeling",
  },
  {
    title:
      "One of us wanting to move locations (within the country or abroad).",
    value: "move_locations",
  },
];

export const FormCB5 = ({
  close,
  handleBack,
}: QuestionnaireProps): JSX.Element => {
  const childrenOnboarding = useAppSelector(
    (state) => state.questionnairesState.cQuestionnaire
  );

  const loadingSaveCQ = useAppSelector(
    (state) => state.questionnairesState.loading
  );
  const loadingTickets = useAppSelector(
    (state) => state.specialTicketsState.loading
  );

  useEffect(() => {
    dispatch(addResourceId(["CR7"]));
  }, []);
  const system_tickets_id = useAppSelector(
    (state) => state.specialTicketsState.tickets
  );
  const system_resources_id = useAppSelector(
    (state) => state.specialResourcesState.resources
  );
  const case_studies_id = useAppSelector(
    (state) => state.caseStudiesState.caseStudies_db
  );
  const system_wresources_id = useAppSelector(
    (state) => state.wellbeing.system_resources_id
  );

  const getFormControlProps = (name: string) => ({
    control: (
      <Checkbox
        color="default"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateInterview(e)
        }
      />
    ),
    name,
    checked:
      childrenOnboarding.particular_concerns[
        name as keyof typeof childrenOnboarding.particular_concerns
      ],
  });

  const dispatch = useAppDispatch();

  const updateInterview = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = ev.target;

    if (checked) {
      switch (name) {
        case "pos_coparenting":
          dispatch(addWResourceId(["WR17", "WR17A"]));
          break;
        case "behavioural":
          dispatch(addWResourceId(["WR21"]));
          break;
        case "child_reluctance":
          dispatch(addWResourceId(["WR20"]));
          break;
        case "supporting_children":
          dispatch(addWResourceId(["WR18"]));
          break;
        case "positive_feeling":
          dispatch(addWResourceId(["WR20"]));
          dispatch(addResourceId(["CR12"]));
          break;
      }
    } else {
      switch (name) {
        case "pos_coparenting":
          dispatch(removeWResourceId(["WR17", "WR17A"]));
          break;
        case "behavioural":
          dispatch(removeWResourceId(["WR21"]));
          break;
        case "child_reluctance":
          dispatch(removeWResourceId(["WR20"]));
          break;
        case "supporting_children":
          dispatch(removeWResourceId(["WR18"]));
          break;
        case "positive_feeling":
          dispatch(removeWResourceId(["WR20"]));
          dispatch(removeResourceId(["CR12"]));
          break;
      }
    }
    dispatch(
      setCQuestionnaire({
        ...childrenOnboarding,
        particular_concerns: {
          ...childrenOnboarding.particular_concerns,
          [`${ev.target.name}`]: ev.target.checked,
        },
      })
    );
  };

  const submitHandle = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    dispatch(saveCOnboarding(childrenOnboarding))
      .unwrap()
      .then(() => {
        if (system_tickets_id.length > 0) {
          dispatch(add_system_tickets(sortArray([...system_tickets_id])))
            .unwrap()
            .then(() => {
              dispatch(loadTickets(2));
              dispatch(resetTicketsIds());
            });
        }

        dispatch(add_system_resources(sortArray([...system_resources_id])))
          .unwrap()
          .then(() => {
            dispatch(loadParenting());

            dispatch(resetResourcesIds());
            close?.();
          });
        dispatch(add_wsystem_resources(sortArray([...system_wresources_id])))
          .unwrap()
          .then(() => {
            dispatch(resetResourcesIds());
          });
        dispatch(add_case_studies(sortArray([...case_studies_id])))
          .unwrap()
          .then(() => {
            dispatch(resetCSIds());
          });
      });
  };

  return (
    <form id="form-7" onSubmit={submitHandle} className={s.form}>
      <span className={s.stepMark}>5</span>

      <>
        <Htag tag="h3">
          We know that many parents have particular concerns about their
          children and co-parenting going forwards, and our expert-led resources
          have been designed to offer you the support you and your children most
          need. We can signpost you to relevant resources here in your Children
          Space. If you’d like this guidance, please tick the topics that
          interest you from the list below. You can tick as many as you’d like.
        </Htag>
        <div className={s.form_oneline}>
          <div className={s.formField}>
            <FormGroup>
              {particular_concerns.map((item, index) => (
                <FormControlLabel
                  {...getFormControlProps(item.value)}
                  key={index}
                  label={item.title}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className={s.descExplain}>
          <p>
            You’ll also have access to resources to help you support your
            children in your Wellbeing Space.
          </p>
        </div>
      </>

      <div className={s.groupButton}>
        <Button
          onClick={() => handleBack()}
          apearance="default"
          className={s.goBack}
          type="button"
        >
          Previous
        </Button>
        <Button
          type="submit"
          disabled={loadingSaveCQ || loadingTickets}
          apearance="secondary"
          className={cn({
            [s.disabled]: loadingSaveCQ || loadingTickets,
          })}
        >
          Next
        </Button>
      </div>
    </form>
  );
};
