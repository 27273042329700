import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IBookmarks } from "./bookmark-interface";
import { Guide } from "store/system_resources/system_resource.interface";

const initBookmarks = {
  wresources: [],
  sresource: [],
  cstudies: [],
  loading: false,
  error: "",
};

const bookmarksState = createSlice({
  name: "@@bookmarks",
  initialState: initBookmarks,
  reducers: {
    setBookmarks: (
      state: IBookmarks,
      action: PayloadAction<Partial<Guide>[]>
    ) => {
      state.cstudies = [];
      state.sresource = [];
      state.wresources = [];
      action.payload.forEach((item: Partial<Guide>) => {
        if (item.type == "sresource") {
          state.sresource.push(item);
        } else if (item.type == "cstudies") {
          state.cstudies.push(item);
        } else if (item.type == "wresources") {
          state.wresources.push(item);
        }
      });
    },
    removeFromList: (
      state: IBookmarks,
      action: PayloadAction<{
        type: string;
        id: number;
      }>
    ) => {
      if (action.payload.type == "sresource") {
        state.sresource = state.sresource.filter(
          (item) => item.id !== action.payload.id
        );
      } else if (action.payload.type == "cstudies") {
        state.cstudies = state.cstudies.filter(
          (item) => item.id !== action.payload.id
        );
      } else if (action.payload.type == "wresources") {
        state.wresources = state.wresources.filter(
          (item) => item.id !== action.payload.id
        );
      }
    },

    reset: () => initBookmarks,
  },
});

export const { reset, setBookmarks, removeFromList } = bookmarksState.actions;

export default bookmarksState.reducer;
