import {
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { DeleteIcon, Htag } from "../..";

import { TicketViewProps } from "./TicketView.props";
import s from "./Ticket.module.css";
import r from "../../ArticleContent/ArticleContent.module.css";
import cn from "classnames";

import ReactHtmlParser from "html-react-parser";

import { downloadAttach } from "../../../controlls/tickets-controll";

import { ITemplates } from "store/tickets/tickets-interface";
import { setNewTicketStatus } from "store/tickets/tickets-thunk";
import { addToEndBoard } from "store/tickets/tickets-reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { getArticleLink } from "controlls/use-link";
import { StackItemComponent } from "components/StackItem/StackItem.component";

export const TicketItemView = ({
  complete,
  deleteItem,
  ticket,
}: TicketViewProps): JSX.Element => {
  const boards = useAppSelector((state) => state.tickets.boards);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSetStatus = (id: number): void => {
    const el = {
      ticket_id: ticket.id,
      status: id,
      sort: boards[id].tickets.length,
    };

    dispatch(setNewTicketStatus(el))
      .unwrap()
      .then(() => {
        dispatch(addToEndBoard(el));
      });
  };

  return (
    <div className={s.ticketViewWrap}>
      <header className={s.ticketViewHeader}>
        <Htag tag="h3">{ticket.title}</Htag>
      </header>
      <div className={s.ticket_desc}>
        <div className={cn(s.ticket_desc_content, r.contentWrap)}>
          {ticket.description && ReactHtmlParser(ticket.description)}
        </div>
        {ticket.guides && ticket.guides.length > 0 && (
          <div className={s.templates}>
            {ticket.guides?.map((guide, index) => (
              <StackItemComponent
                key={index}
                onClick={(e) => navigate(getArticleLink("sresource", guide.id))}
                className={s.attachmentContainer}
                data={{
                  title: guide.title,
                  type: "resource",
                  reading_time: guide.reading_time,
                }}
              />
            ))}
          </div>
        )}
        {ticket.attachments && ticket?.attachments.length > 0 && (
          <div className={s.templates}>
            {ticket.attachments.map((template: ITemplates, index: number) => (
              <StackItemComponent
                data={{
                  title: template.name || "",
                  type: template.type,
                }}
                onClick={() => {
                  if (!ticket.special_ticket_id || !template.name) return;

                  downloadAttach(
                    template.id,
                    +ticket.special_ticket_id,
                    template.name
                  );
                }}
                key={index}
              >
              
              </StackItemComponent>
            ))}
          </div>
        )}
      </div>

      <List
        component="nav"
        className={s.bottomNav}
        aria-labelledby="nested-list-subheader"
      >
        {ticket.status !== 3 && (
          <ListItemButton onClick={() => complete(1)} className={s.ticketBtn}>
            <ListItemIcon className={s.navIcon}>
              <Checkbox
                color="default"
                name="terms"
                onChange={(e) => {
                  handleSetStatus(Number(!ticket.status));
                }}
                checked={!!ticket.status}
                className={ticket.status ? s.checkboxChecked : s.unchecked}
              />
            </ListItemIcon>
            <ListItemText primary="Task complete" />
          </ListItemButton>
        )}

        <ListItemButton
          onClick={deleteItem}
          className={cn(s.ticketBtn, s.deleteBtn)}
        >
          <ListItemIcon className={s.navIcon}>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText className={s.deleteText} primary="Delete" />
        </ListItemButton>
      </List>
    </div>
  );
};
