import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { dragDrop } from "store/tickets/tickets-reducer";
import { setNewTicketStatus } from "store/tickets/tickets-thunk";
import cn from "classnames";
import s from "./Boards.module.css";
import { Htag } from "components";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { IBoard, ITicket } from "store/tickets/tickets-interface";
import { useParams } from "react-router-dom";
import TicketItem from "components/Tickets/TicketItem/TicketItem.component";
import { Statuses } from "store/tickets/tickets-selectors";
import { updateMilestoneCompleting } from "store/milestones/milestone-reducer";
const TaskList = styled.div``;

export const BoardsComponent = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const boards = useAppSelector((state) => state.tickets.boards);

  const toDoLength = useAppSelector(
    (state) => state.tickets.boards[Statuses.TODO]?.tickets.length
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (toDoLength == 0) {
      dispatch(updateMilestoneCompleting({ id, complete_percent: 100 }));
    } else {
      dispatch(updateMilestoneCompleting({ id, complete_percent: 0 }));
    }
  }, [toDoLength]);

  const DragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const sourceColumn = boards[Number(source.droppableId)];
    const destColumn = boards[Number(destination.droppableId)];

    const newSourceTickets = Array.from(sourceColumn.tickets);
    let newDestTickets = Array.from(destColumn.tickets);
    const [draggedItem] = newSourceTickets.splice(source.index, 1);

    if (destination.droppableId !== source.droppableId) {
      newDestTickets = Array.from(destColumn.tickets);
    } else {
      newDestTickets = Array.from(newSourceTickets);
    }

    newDestTickets.splice(destination.index, 0, draggedItem);

    const newSourceColumn = {
      ...sourceColumn,
      tickets: newSourceTickets,
    };

    const newDestColumn = {
      ...destColumn,
      tickets: newDestTickets,
    };

    dispatch(dragDrop(newSourceColumn));
    dispatch(dragDrop(newDestColumn));

    if (draggedItem.sort !== undefined) {
      dispatch(
        setNewTicketStatus({
          ticket_id: draggedItem.id,
          status: Number(destination.droppableId),
          sort: destination.index,
        })
      );
    }
  };

  return (
    <div className={s.boardWrap}>
      <DragDropContext onDragEnd={DragEnd}>
        {boards.map((board: IBoard) => (
          <div key={`board-${board.id}`} className={cn(s.ticketsColumn)}>
            <Htag tag="h3" className={s.boardTitle}>
              {board.title}
            </Htag>
            <div className={s.tickets_content}>
              <Droppable droppableId={board.id.toString()}>
                {(provided) => (
                  <TaskList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {board.tickets.map((x: ITicket, index: number) => (
                      <TicketItem
                        key={x.id}
                        type={board.title}
                        ticket={x}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
            </div>
          </div>
        ))}
      </DragDropContext>
    </div>
  );
};
