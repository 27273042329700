import { IArticle } from "../blog/blog.interfaces";
import { IError } from "../root-interface";

export enum PageType {
  step = 0,
  wellbeing = 1,
  parenting,
}

export type FileProps = {
  path: string;
  lastModified: number;
  slice: () => void;
  stream: () => void;
  text: () => void;
  arrayBuffer: ArrayBuffer;
  name: string;
  size: number;
  type: string;
  id: number;
};

export type IAttach = {
  file: FileProps;
  name?: string;
  type: string | number;
  id?: number;
};

export interface ISource {
  attachments: any;
  bookmark?: boolean;
}

export interface Guide extends IArticle, ISource {
  unique_number?: string;
  space_id?: number | null;
  milestone_id?: number | null;
  image?: Blob | null | string;
  tags: string[];
  resource_id?: string;
  type?: string;
  category_id?: number | null;
  short_desc?: string;
  id?: number;
  is_wellbeing_resource?: boolean;
  domestic_abuse?: string | null;
}

export interface ISystemResources {
  resources: string[];
  resources_db: Guide[];
  error: string | IError;
  loading: boolean;
}
