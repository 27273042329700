import { Button, ErrorField, Htag, Newsletter } from "components";
import { useFormik } from "formik";
import * as yup from "yup";
import s from "./GuideForm.module.css";
import axios from "axios";
import { BASE_URL } from "store/config";
import { useState } from "react";
import { Alert, FormControl, Snackbar } from "@mui/material";
import cn from "classnames";
import { Link } from "react-router-dom";

export const GuideFormComponent = () => {
  const [homeSettings, setHomeSettings] = useState({
    nlError: "",
    open: false,
    snakbar: false,
  });
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      axios
        .post(
          `${BASE_URL}/newsletter/subscribe`,
          { email: formik.values.email },
          {
            headers: {
              Accept: "application/json",
            },
            responseType: "json",
          }
        )
        .then(() => {
          setHomeSettings({
            ...homeSettings,
            open: true,
            nlError: "",
            snakbar: true,
          });

          formik.resetForm();
          window.datahappy
            .trackLeadCreation({
              eventProperties: {
                value: 10, // common to track a value for lead events
                currency: "GBP",
                lead_source: "newsletter",
              },
              userTraits: values,
            })
       
            .catch((err: Error) => {
              console.log(err);
            });
        })
        .catch((err) => {
          setHomeSettings({
            ...homeSettings,
            open: false,
            nlError: err.response.data.error,
          });
        });
    },
  });
  return (
    <>
      <div className={s.left_part}>
        <Htag tag="h2">Our guidance in your inbox</Htag>
        <Newsletter />
      </div>
      <div className={s.right_part}>
        <p>
          Sign up for separation tips and guidance, straight from the experts to
          your inbox.
        </p>
        <p>Our newsletter is free and you can unsubscribe whenever you want.</p>

        <form id="form-1" onSubmit={formik.handleSubmit}>
          <FormControl fullWidth className={s.emailGroup}>
            <input
              id="email"
              name="email"
              type="text"
              className={cn(s.form_field, {
                [s.error_field]: formik.errors.email && formik.touched.email,
              })}
              placeholder="Your email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Button apearance="dark" type="submit">
              Subscribe
            </Button>
            {formik.touched.email && formik.errors.email && (
              <ErrorField error={formik.errors.email} />
            )}
          </FormControl>

          {homeSettings.nlError && (
            <ErrorField error={"You already subscribed"} />
          )}

          <p>
            By subscribing, you consent to receive information from
            SeparateSpace. You can unsubscribe at any time. View our{" "}
            <Link
              reloadDocument
              to="/privacy-policy"
              target="_blank"
              className="hover-underline-animation_back"
            >
              privacy policy.
            </Link>
          </p>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={homeSettings.snakbar}
        autoHideDuration={6000}
        onClose={() => setHomeSettings({ ...homeSettings, snakbar: false })}
      >
        <Alert
          onClose={() => setHomeSettings({ ...homeSettings, snakbar: false })}
          severity="success"
          sx={{ width: "100%" }}
        >
          Great! Thank you for your subscription
        </Alert>
      </Snackbar>
    </>
  );
};
