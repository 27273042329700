import { ChangeEvent, useState } from "react";
import { Button, ErrorField, Htag } from "components";
import { Checkbox, FormControlLabel } from "@mui/material";
import s from "./AtlaAI.module.css";
import Chatbot from "./ChatBot/ChatBot.component";

export const AtlaAIPageComponent = (): JSX.Element => {
  const [agreegment, setAgreegment] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formError, setFormError] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!formError) {
      if (agreegment) {
        setFormValid(true);
      } else {
        setFormError(
          "To proceed you need to confirm you understand Atla AI is a chatbot which doesn’t offer legal advice."
        );
        setFormValid(false);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!agreegment) {
      setFormError("");
      setAgreegment(e.target.checked);
    }
    {
      setAgreegment(e.target.checked);
    }
  };

  if (!formValid) {
    return (
      <div className={s.atlaContainer}>
        <Htag tag="h1">Welcome to Atla AI</Htag>
        <div className={s.importantText}>
          <p>
            To use Atla AI type a simple short question into the chat bar and
            wait for your answer. Atla AI responds well to short, and general
            questions like: “What do I need to know about pensions?”
          </p>
        </div>
        <p>
          Atla AI is a chatbot designed to help you access and navigate
          divorce-related resources on the Separate Space platform. It's not a
          real person or a lawyer.
        </p>
        <p>
          It can help guide you through the divorce process and offer tips on
          practicalities such as, supporting children or handling finances
          during the process, as well as point you to useful resources.
        </p>
        <p>Remember:</p>
        <ul className="ul">
          <li>Atla AI can't give legal advice.</li>
          <li>
            Atla AI is still learning, and its information might not be 100%
            accurate or fit your specific situation. Please double check the
            answer by reading the source material linked.
          </li>
          <li>
            Please do not provide personal, confidential or sensitive
            information to Atla AI.
          </li>
        </ul>
        <p>
          If at any point you are uncertain about the way forward, you should
          obtain professional or specialist advice from independent legal, tax,
          accounting or financial advisors before taking or refraining from any
          action.
        </p>
        <form className={s.agreementForm} onSubmit={handleSubmit}>
          <FormControlLabel
            control={<Checkbox checked={agreegment} onChange={handleChange} />}
            label="I understand Atla AI is a chatbot which doesn't provide legal advice "
          />
          {formError && <ErrorField error={formError} />}
          <Button type="submit" apearance="dark">
            Continue
          </Button>
        </form>
      </div>
    );
  } else {
    return (
      <div className={s.atlaAIWrapper}>
        <Chatbot />
      </div>
    );
  }
};
